import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, Paper } from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import themis_common from '../store/themis_common_pb';
import themis_api from '../store/themis_api_pb';
import { client } from '../store/themisClient';
import { format } from 'date-fns';
const SeasonEditor: React.FC = () => {
  const theme = useTheme();
  const [seasons, setSeasons] = useState<themis_common.Season.AsObject[]>([]);
  const [selectedSeason, setSelectedSeason] = useState<themis_common.Season.AsObject | null>(null);
  const [selectedDivision, setSelectedDivision] = useState<themis_common.Division.AsObject | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState<'add' | 'edit'>('add');
  const [dialogData, setDialogData] = useState<themis_common.Division.AsObject>({
    id: 0,
    name: '',
    divisionDescriptor: '',
    competitionTypeDescriptor: '',
    levelDescriptor: '',
    ageCategoryDescriptor: '',
    danceCategoryDescriptor: '',
    isDance: false,
    lastScrape: '',
    usasfDivisionId: '',
    divisionSplitsList: []
  });
  const [filter, setFilter] = useState('');
  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const today = format(new Date(), 'yyyy-MM-dd');
        const request = new themis_api.GetSeasonsRequest();
        request.setRequestedDate(today);
        const response = await client.getSeasons(request, {});
        const seasons = response.toObject().seasonsList;
        setSeasons(seasons);
      } catch (error) {
        console.error('Error fetching seasons:', error);
      }
    };
    fetchSeasons();
  }, []);
  const handleSeasonSelect = (season: themis_common.Season.AsObject) => {
    setSelectedSeason(season);
    setSelectedDivision(null); // Reset selected division when a new season is selected
  };
  const handleDivisionSelect = (division: themis_common.Division.AsObject) => {
    setSelectedDivision(division);
  };
  const handleOpenDialog = (mode: 'add' | 'edit', division?: themis_common.Division.AsObject) => {
    setDialogMode(mode);
    setDialogData(division || {
      id: 0,
      name: '',
      divisionDescriptor: '',
      competitionTypeDescriptor: '',
      levelDescriptor: '',
      ageCategoryDescriptor: '',
      danceCategoryDescriptor: '',
      isDance: false,
      lastScrape: '',
      usasfDivisionId: '',
      divisionSplitsList: []
    });
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData({
      id: 0,
      name: '',
      divisionDescriptor: '',
      competitionTypeDescriptor: '',
      levelDescriptor: '',
      ageCategoryDescriptor: '',
      danceCategoryDescriptor: '',
      isDance: false,
      lastScrape: '',
      usasfDivisionId: '',
      divisionSplitsList: []
    });
  };
  const handleSaveDivision = () => {
    if (selectedSeason) {
      const updatedDivisions = [...selectedSeason.divisionsList];
      if (dialogMode === 'add') {
        updatedDivisions.push({
          ...dialogData,
          id: Date.now()
        });
      } else {
        const index = updatedDivisions.findIndex(d => d.id === dialogData.id);
        if (index !== -1) {
          updatedDivisions[index] = dialogData;
        }
      }
      const updatedSeason = {
        ...selectedSeason,
        divisionsList: updatedDivisions
      };
      setSeasons(prev => prev.map(s => s.id === updatedSeason.id ? updatedSeason : s));
      setSelectedSeason(updatedSeason);
    }
    handleCloseDialog();
  };
  const handleDeleteDivision = (divisionId: number) => {
    if (selectedSeason) {
      const updatedDivisions = selectedSeason.divisionsList.filter(d => d.id !== divisionId);
      const updatedSeason = {
        ...selectedSeason,
        divisionsList: updatedDivisions
      };
      setSeasons(prev => prev.map(s => s.id === updatedSeason.id ? updatedSeason : s));
      setSelectedSeason(updatedSeason);
      setSelectedDivision(null); // Reset selected division if it is deleted
    }
  };
  const filteredDivisions = selectedSeason?.divisionsList.filter(division => division.name.toLowerCase().includes(filter.toLowerCase())) || [];
  return <Box style={{
    display: 'flex',
    height: '100vh',
    padding: 16
  }}>
      <Box style={{
      width: '20%',
      borderRight: `1px solid ${theme.palette.divider}`,
      paddingRight: 16,
      overflowY: 'auto'
    }}>
        <Typography variant="h6" gutterBottom>
          Seasons
        </Typography>
        <List>
          {seasons.map(season => <ListItem key={season.id} button selected={selectedSeason?.id === season.id} onClick={() => handleSeasonSelect(season)}>
              <ListItemText primary={season.name} />
            </ListItem>)}
        </List>
      </Box>
      <Box sx={{
      width: '40%',
      pl: 2,
      maxHeight: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
        {selectedSeason && <>
            <Typography variant="h6" gutterBottom>
              {selectedSeason.name} - Divisions
            </Typography>
            <TextField label="Filter Divisions" variant="outlined" fullWidth value={filter} onChange={e => setFilter(e.target.value)} sx={{
          mb: 2
        }} />
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleOpenDialog('add')} sx={{
          mb: 2
        }}>
              Add Division
            </Button>
            <Paper sx={{
          overflowY: 'auto',
          maxHeight: '600px'
        }}>
              <List>
                {filteredDivisions.map(division => <ListItem key={division.id} button selected={selectedDivision?.id === division.id} onClick={() => handleDivisionSelect(division)} secondaryAction={<>
                        <IconButton edge="end" aria-label="edit" onClick={() => handleOpenDialog('edit', division)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDivision(division.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </>}>
                    <ListItemText primary={division.name} />
                  </ListItem>)}
              </List>
            </Paper>
          </>}
      </Box>
      <Box sx={{
      width: '40%',
      pl: 2,
      overflowY: 'auto'
    }}>
        {selectedDivision && <>
            <Typography variant="h6" gutterBottom>
              Edit Division
            </Typography>
            <TextField label="Division Name" variant="outlined" fullWidth value={selectedDivision.name} onChange={e => setSelectedDivision({
          ...selectedDivision,
          name: e.target.value
        })} sx={{
          mb: 2
        }} />
            <TextField label="Division Descriptor" variant="outlined" fullWidth value={selectedDivision.divisionDescriptor} onChange={e => setSelectedDivision({
          ...selectedDivision,
          divisionDescriptor: e.target.value
        })} sx={{
          mb: 2
        }} />
            <TextField label="Competition Type Descriptor" variant="outlined" fullWidth value={selectedDivision.competitionTypeDescriptor} onChange={e => setSelectedDivision({
          ...selectedDivision,
          competitionTypeDescriptor: e.target.value
        })} sx={{
          mb: 2
        }} />
            <TextField label="Level Descriptor" variant="outlined" fullWidth value={selectedDivision.levelDescriptor} onChange={e => setSelectedDivision({
          ...selectedDivision,
          levelDescriptor: e.target.value
        })} sx={{
          mb: 2
        }} />
            <TextField label="Age Category Descriptor" variant="outlined" fullWidth value={selectedDivision.ageCategoryDescriptor} onChange={e => setSelectedDivision({
          ...selectedDivision,
          ageCategoryDescriptor: e.target.value
        })} sx={{
          mb: 2
        }} />
            <TextField label="Dance Category Descriptor" variant="outlined" fullWidth value={selectedDivision.danceCategoryDescriptor} onChange={e => setSelectedDivision({
          ...selectedDivision,
          danceCategoryDescriptor: e.target.value
        })} sx={{
          mb: 2
        }} />
            <TextField label="USASF Division ID" variant="outlined" fullWidth value={selectedDivision.usasfDivisionId} onChange={e => setSelectedDivision({
          ...selectedDivision,
          usasfDivisionId: e.target.value
        })} sx={{
          mb: 2
        }} />
            <Button variant="contained" onClick={handleSaveDivision} sx={{
          mt: 2
        }}>
              Save Changes
            </Button>
          </>}
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{dialogMode === 'add' ? 'Add Division' : 'Edit Division'}</DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" id="name" label="Division Name" type="text" fullWidth variant="standard" value={dialogData.name} onChange={e => setDialogData({
          ...dialogData,
          name: e.target.value
        })} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveDivision}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>;
};
export default SeasonEditor;