import themis_common from "store/themis_common_pb"
import { buildPerson } from "store/program/programBuilders"

export const buildProducer = (producerObject: themis_common.Producer.AsObject): themis_common.Producer => {
  const producer = new themis_common.Producer()
  producer.setId(producerObject.id)
  producer.setName(producerObject.name)
  producer.setAddress(producerObject.address)
  producer.setAddress2(producerObject.address2)
  producer.setPostalCode(producerObject.postalCode)
  producer.setCountry(producerObject.country)
  producer.setState(producerObject.state)
  producer.setCity(producerObject.city)
  producer.setWebsite(producerObject.website)
  producer.setEmail(producerObject.email)
  producer.setPhone(producerObject.phone)
  producer.setLogo(producerObject.logo)
  producer.setAccountEmail(producerObject.accountEmail)
  return producer
}

export const buildBrandRequest = (brandObject: themis_common.Brand.AsObject): themis_common.Brand => {
  const brand = new themis_common.Brand()
  brand.setProducerId(brandObject.producerId)
  brand.setId(brandObject.id)
  brand.setName(brandObject.name)
  brand.setName(brandObject.name)
  brand.setLogo(brandObject.logo)
  brand.setCountry(brandObject.country)
  brand.setAddress(brandObject.address)
  brand.setAddress2(brandObject.address2)
  brand.setState(brandObject.state)
  brand.setCity(brandObject.city)
  brand.setEmail(brandObject.email)
  brand.setPhone(brandObject.phone)
  brand.setPostalCode(brandObject.postalCode)
  brand.setState(brandObject.state)
  brand.setWebsite(brandObject.website)
  brand.setCurrency(brandObject.currency)
  brand.setGuardianRequired(brandObject.guardianRequired)
  return brand
}

export const buildEvent = (eventObject: themis_common.Event.AsObject): themis_common.Event => {
  const event = new themis_common.Event()
  event.setProducerId(eventObject.producerId)
  event.setBrandId(eventObject.brandId)
  event.setId(eventObject.id)
  event.setName(eventObject.name)
  event.setLogo(eventObject.logo)
  event.setCountry(eventObject.country)
  event.setState(eventObject.state)
  event.setCity(eventObject.city)
  event.setAddress(eventObject.address)
  event.setAddress2(eventObject.address2)
  event.setPostalCode(eventObject.postalCode)
  event.setVenueName(eventObject.venueName)
  event.setEmail(eventObject.email)
  event.setPhone(eventObject.phone)
  event.setWebsite(eventObject.website)
  event.setInfo(eventObject.info)
  event.setCurrency(eventObject.currency)
  event.setFirstDay(eventObject.firstDay)
  event.setSeasonsList(buildSeasonsList(eventObject.seasonsList))
  event.setWarmupTime(eventObject.warmupTime)
  event.setRotationTime(eventObject.rotationTime)
  event.setMaxTeams(eventObject.maxTeams)
  event.setAutoCloseRegistrationAfterMaxTeams(eventObject.autoCloseRegistrationAfterMaxTeams)
  event.setRegistrationSalesTax(eventObject.registrationSalesTax)
  event.setRegistrationSalesTaxRateList(buildRegistrationSalesTaxRate(eventObject.registrationSalesTaxRateList))
  return event
}

export const buildRegistrationSalesTaxRate = (registrationSalesTaxRateObject: themis_common.SalesTaxRate.AsObject[]): themis_common.RegistrationSalesTaxRate => {
  const registrationSalesTaxRateList = new Array<themis_common.SalesTaxRate>()
  registrationSalesTaxRateObject.forEach(salesTaxRate => {
    const newSalesTaxRate = new themis_common.SalesTaxRate()
    newSalesTaxRate.setRate(salesTaxRate.rate)
    newSalesTaxRate.setKey(salesTaxRate.key)
    newSalesTaxRate.setFeeRate(salesTaxRate.feeRate)
    newSalesTaxRate.setIsFee(salesTaxRate.isFee)
    registrationSalesTaxRateList.push(newSalesTaxRate)
    //registrationSalesTaxRateList.push(buildSalesTaxRate(salesTaxRate))
  })
  return registrationSalesTaxRateList
}

export const buildSeasonsList = (seasonsListObject: themis_common.Season.AsObject[]): Array<themis_common.Season> => {
  const seasonList = new Array<themis_common.Season>()
  seasonsListObject.forEach(season => {
    seasonList.push(buildSeason(season))
  })
  return seasonList
}

export const buildSeason = (seasonObject: themis_common.Season.AsObject): themis_common.Season => {
  const season = new themis_common.Season()
  season.setId(seasonObject.id)
  season.setName(seasonObject.name)
  season.setStartDate(seasonObject.startDate)
  season.setEndDate(seasonObject.endDate)
  season.setCountry(seasonObject.country)
  if (seasonObject.scoringAuthority) {
    season.setScoringAuthority(buildScoringAuthority(seasonObject.scoringAuthority))
  }
  // season.setDivisionsList(buildDivisionsList(seasonObject.divisionsList)) // Currently works, just extra unneeded data
  return season
}

export const buildScoringAuthority = (scoringAuthorityObject: themis_common.ScoringAuthority.AsObject): themis_common.ScoringAuthority => {
  const scoringAuthority = new themis_common.ScoringAuthority()
  scoringAuthority.setId(scoringAuthorityObject.id)
  scoringAuthority.setName(scoringAuthorityObject.name)
  // scoringAuthority.setDivisionsList(buildDivisionsList(scoringAuthorityObject.divisionsList)) // Currently works, just extra unneeded data
  return scoringAuthority
}

export const buildDivisionsList = (divisionListObject: themis_common.Division.AsObject[]): Array<themis_common.Division> => {
  const DivisionList = new Array<themis_common.Division>()
  divisionListObject.forEach(division => {
    DivisionList.push(buildDivision(division))
  })
  return DivisionList
}

export const buildDivision = (divisionObject: themis_common.Division.AsObject): themis_common.Division => {
  const division = new themis_common.Division()
  division.setId(divisionObject.id)
  division.setName(divisionObject.name)
  // Reference if we want to add these in the future
  // if (divisionObject.allStarType) division.setAllStarType(buildAllStarType(divisionObject.allStarType))
  // if (divisionObject.season) division.setSeason(buildSeason(divisionObject.season))
  // if (divisionObject.tier) division.setTier(buildTier(divisionObject.tier))
  // if (divisionObject.level) division.setLevel(buildLevel(divisionObject.level))
  division.setDivisionDescriptor(divisionObject.divisionDescriptor)
  division.setCompetitionTypeDescriptor(divisionObject.competitionTypeDescriptor)
  division.setLevelDescriptor(divisionObject.levelDescriptor)
  division.setAgeCategoryDescriptor(divisionObject.ageCategoryDescriptor)
  division.setDanceCategoryDescriptor(divisionObject.danceCategoryDescriptor)
  division.setIsDance(divisionObject.isDance)
  division.setLastScrape(divisionObject.lastScrape)
  division.setUsasfDivisionId(divisionObject.usasfDivisionId)
  // Reference if we want to add these in the future
  // if (divisionObject.divisionRestriction) division.setDivisionRestriction(buildDivisionRestriction(divisionObject.divisionRestriction))
  // if (divisionObject.divisionSplitsList) division.setDivisionSplitsList(buildDivisionSplitsList(divisionObject.divisionSplitsList))
  return division
}

export const buildJudge = (judgeId: number | undefined, producerId: number, judgePerson: themis_common.Person.AsObject, validateBrandId?: number): themis_common.Judge => {
  const judgeRequest = new themis_common.Judge()
  if (judgeId) {
    judgeRequest.setId(judgeId)
  }
  judgeRequest.setProducerId(producerId)
  judgeRequest.setPerson(buildPerson(judgePerson))

  judgeRequest.setName(`${judgePerson.legalFirstName} ${judgePerson.legalLastName}`)

  if (validateBrandId && validateBrandId > 0) judgeRequest.setBrandId(validateBrandId)

  const producer = new themis_common.Producer()
  producer.setId(producerId)
  judgeRequest.addJudgeProducers(producer)
  return judgeRequest
}
