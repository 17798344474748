import {
  applyMiddleware,
  combineReducers,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { IUserState, userReducer, initialUserState } from './user/userReducer';
import { IProgramState, programReducer, initialProgramState } from './program/programReducer';
import { IProducerState, producerReducer, initialProducerState } from './producer/producerReducer';
import * as Sentry from '@sentry/react';
import loadingReducer from './loadingReducer';
import loaderMiddleware from './loadingMiddleware';

// Sentry configuration
Sentry.init({
  dsn: "https://2052909a960df989e9b8468608c76750@o4504300865191936.ingest.us.sentry.io/4507921871929344",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAttributes: ['password'],
    }),
    Sentry.feedbackIntegration({
      colorScheme: "system",
    })
  ],
  attachStacktrace: true,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", "ca.themis.events", "themis.events", "10.6.1.91"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Application state interface
export interface IAppState {
  userState: IUserState;
  programState: IProgramState;
  producerState: IProducerState;
  loading: { activeRequests: number };
}

export const initialState: IAppState = {
  userState: initialUserState,
  programState: initialProgramState,
  producerState: initialProducerState,
  loading: { activeRequests: 0 },
};

// Create the root reducer
const rootReducer = combineReducers<IAppState>({
  userState: userReducer,
  programState: programReducer,
  producerState: producerReducer,
  loading: loadingReducer,
});

// Create Sentry Redux Enhancer
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

// Apply thunk and Sentry middleware
const middlewares = [thunk, loaderMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);

// Conditionally apply DevTools and Sentry enhancer
const composedEnhancers =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(middlewareEnhancer, sentryReduxEnhancer)
    : composeWithDevTools(middlewareEnhancer, sentryReduxEnhancer);

// Create and export the store (use this directly)
export const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
);