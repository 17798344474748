import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Menu, MenuItem, Select, Switch } from '@material-ui/core';
import { BothSides, logoutUser, refreshUser } from "store/user/userActions";
import { Menu as MenuIcon } from '@material-ui/icons';
import { useStyles } from "lib/theme";
import { AnyDispatchType } from 'lib/constants';
import { IAppState } from 'store/store';
import { selectUserImpersonated } from 'store/user/userSelectors';
import { JudgeEventPermissions, ProducerPermissions, ProgramPermissions } from "store/user/userReducer";
import { selectBothSides, selectCurrentLoginState, selectTryLogin, selectUserJudgeEvents, selectUserProducers, selectUserPrograms } from "store/user/userSelectors";
const showUsername = (username: string, classes: Record<any, string>, _dispatch: AnyDispatchType): ReactElement => {
  return <Grid item container xs alignContent="center" direction="column">
      <Button className={classes.button}>
        {username}
      </Button>
    </Grid>;
};
const showLogin: React.FC<Record<any, string>> = (classes): ReactElement => {
  return <Grid item xs>
      <Button component={RouterLink} to={'/login/'} className={classes.button}>
        Login
      </Button>
    </Grid>;
};
interface HeaderMenuProps {
  username: string;
  bothSides: BothSides;
  setCurrentProgram: Function;
  setCurrentProducer: Function;
  loggedIn: boolean;
  tryLogin: boolean;
  userState: string;
  setUserState: Function;
}
const HeaderMenu: React.FC<HeaderMenuProps> = ({
  username,
  bothSides,
  loggedIn,
  tryLogin,
  setCurrentProgram,
  setCurrentProducer,
  userState,
  setUserState
}): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const darkStateStorage = localStorage.getItem('darkState') || 'dark';
  const oldUsername = localStorage.getItem('OLD_USERNAME');
  const userImpersonated = useSelector((state: IAppState) => selectUserImpersonated(state));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [darkState, setDarkState] = useState(darkStateStorage === 'dark');
  const programs: ProgramPermissions = useSelector((state: IAppState) => selectUserPrograms(state));
  const producers: ProducerPermissions = useSelector((state: IAppState) => selectUserProducers(state));
  const judgeEvents: JudgeEventPermissions = useSelector((state: IAppState) => selectUserJudgeEvents(state));
  const handleLogout = (dispatch: AnyDispatchType) => {
    return () => {
      const doLogout = logoutUser();
      setCurrentProgram(undefined);
      setCurrentProducer(undefined);
      doLogout(dispatch);
    };
  };
  const handleUserTypeChange = useCallback(async (state: string, refresh = true): Promise<void> => {
    // This handles the swap between program and producer
    history.push("/");
    setUserState(state);
    localStorage.setItem('programOrProducer', state);
    if (refresh) {
      await refreshUser(dispatch);
      setAnchorEl(null);
    }
  }, [dispatch, history, setUserState]);
  useEffect(() => {
    // Switch sides if user only has stuff on one side
    if (loggedIn && !tryLogin) {
      if (userState === 'program') {
        if (!bothSides.program && bothSides.judge) {
          handleUserTypeChange('judge');
        }
      } else if (userState === 'producer') {
        if (!bothSides.producer && bothSides.judge) {
          handleUserTypeChange('judge');
        }
      } else if (userState === 'judge') {
        if (!bothSides.judge && bothSides.producer) {
          handleUserTypeChange('producer');
        }
      }
    }
  }, [bothSides, userState, loggedIn, tryLogin, handleUserTypeChange]);
  const handleThemeChange = (state: boolean) => {
    // This changes the theme from dark to light, etc but requires a hard reload to do so.
    setDarkState(state);
    localStorage.setItem('darkState', state ? 'dark' : 'dark');
    window.location.reload();
  };
  const showMenu = () => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return <div>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.button} style={{
        paddingLeft: 20
      }}>
          <MenuIcon />
        </Button>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem key="Menu-Account" component={RouterLink} to={'/Account'} onClick={(_e: React.MouseEvent<HTMLElement>) => handleClose()}>Account</MenuItem>
          {/* <MenuItem key="Menu-Theme" >
            <span onClick={(_) => { handleThemeChange(false) }}>Light Theme</span>
            <Switch checked={darkState} onChange={(e) => {handleThemeChange(e.target.checked)}} />
            <span onClick={(_) => { handleThemeChange(true) }}>Dark Theme</span>
           </MenuItem> */}
          {bothSides.program && bothSides.producer && bothSides.judge ? <MenuItem key="Menu-Side-Switch">
            <Select style={{
            width: "100%"
          }} variant="outlined" id="userType-select" value={userState} onChange={e => {
            handleUserTypeChange(String(e.target.value), true);
          }}>
              <MenuItem value='judge'>Judge</MenuItem>
              <MenuItem value='program'>Program</MenuItem>
              <MenuItem value='producer'>Producer</MenuItem>
            </Select>
          </MenuItem> : []}
          {Object.keys(judgeEvents).length > 0 && Object.keys(producers).length > 0 && Object.keys(programs).length === 0 ? <MenuItem key="Menu-Side-Switch">
            <span onClick={_ => {
            handleUserTypeChange('judge', true);
          }}>Judge</span>
            <Switch checked={userState === 'producer'} onChange={e => {
            handleUserTypeChange(e.target.checked ? 'producer' : 'judge');
          }} />
            <span onClick={_ => {
            handleUserTypeChange('producer', true);
          }}>Event Producer</span>
          </MenuItem> : []}
          {Object.keys(judgeEvents).length > 0 && Object.keys(programs).length > 0 && Object.keys(producers).length === 0 ? <MenuItem key="Menu-Side-Switch">
            <span onClick={_ => {
            handleUserTypeChange('judge', true);
          }}>Judge</span>
            <Switch checked={userState === 'producer'} onChange={e => {
            handleUserTypeChange(e.target.checked ? 'program' : 'judge');
          }} />
            <span onClick={_ => {
            handleUserTypeChange('program', true);
          }}>Program Owner</span>
          </MenuItem> : []}
          {bothSides.program && bothSides.producer && !bothSides.judge ? <MenuItem key="Menu-Side-Switch">
            <span onClick={_ => {
            handleUserTypeChange('producer', true);
          }}>Event Producer</span>
            <Switch checked={userState === 'producer'} onChange={e => {
            handleUserTypeChange(e.target.checked ? 'producer' : 'program');
          }} />
            <span onClick={_ => {
            handleUserTypeChange('program', true);
          }}>Program Owner</span>
          </MenuItem> : []}
          {Object.keys(programs).length > 0 || Object.keys(producers).length > 0 ? <MenuItem key="Menu-ManagePaymentMethods" component={RouterLink} to={'/ManagePaymentMethods'} onClick={(_e: React.MouseEvent<HTMLElement>) => handleClose()}>Manage Payment Methods</MenuItem> : []}
          <MenuItem key="Menu-Refresh-User" onClick={_ => {
          refreshUser(dispatch);
          handleClose();
        }}>Refresh Login</MenuItem>
          {!userImpersonated ? [<MenuItem key="Menu-ShareLogin" component={RouterLink} to={'/ShareLogin'} onClick={(_e: React.MouseEvent<HTMLElement>) => handleClose()}>Generate Access Code</MenuItem>, <MenuItem key="Menu-AccessCodeLogin" component={RouterLink} to={'/AccessCodeLogin'} onClick={(_e: React.MouseEvent<HTMLElement>) => handleClose()}>Use Access Code</MenuItem>] : []}
          <MenuItem key="Menu-Logout" onClick={_ => {
          const logout = handleLogout(dispatch);
          logout();
          handleClose();
          history.replace("/Login");
        }}>{!userImpersonated ? "Logout" : `Switch Back to ${oldUsername}`}</MenuItem>
        </Menu>
      </div>;
  };
  return <Grid item container className={classes.menu} md={12}>
      <Grid item md={5}>
        {username ? showUsername(username, classes, dispatch) : showLogin(classes)}
      </Grid>
      <Grid item md={3}>
        {username ? showMenu() : <></>}
      </Grid>
    </Grid>;
};
export default HeaderMenu;