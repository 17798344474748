import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LinearProgress, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Define custom styles for the loader
const useStyles = makeStyles(theme => ({
  loaderContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    // Semi-transparent dark overlay
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300 // High enough to appear above all content
  },
  progressBar: {
    width: '80%'
  },
  message: {
    marginTop: theme.spacing(2),
    color: '#fff' // White text to contrast with dark overlay
  }
}));
const MINIMUM_DISPLAY_TIME = 500; // Minimum display time in milliseconds (e.g., 2 seconds)
// const messages = [
//   "Loading web assets.",
//   "Fetching network resources.",
//   "Initializing HTTP requests.",
//   "Rendering web components.",
//   "Processing API calls.",
//   "Loading static files.",
//   "Retrieving server data.",
//   "Compiling front-end scripts.",
//   "Synchronizing client state.",
//   "Caching network responses.",
//   "Parsing HTML content.",
//   "Loading JavaScript modules.",
//   "Fetching remote assets.",
//   "Updating UI components.",
//   "Loading application resources.",
//   "Processing user inputs.",
//   "Retrieving API endpoints.",
//   "Parsing JSON data.",
//   "Rendering dynamic content.",
//   "Fetching web data.",
//   "Loading CSS styles.",
//   "Initializing web components.",
//   "Synchronizing server data.",
//   "Fetching content dynamically.",
//   "Loading web services.",
//   "Processing web requests.",
//   "Rendering page elements.",
//   "Updating web interface.",
//   "Retrieving web content.",
//   "Loading frontend assets.",
//   "Fetching dynamic resources.",
//   "Caching application data.",
//   "Parsing server responses.",
//   "Initializing network operations.",
//   "Loading client scripts.",
//   "Processing page elements.",
//   "Fetching database records.",
//   "Updating user interface.",
//   "Rendering interactive elements.",
//   "Retrieving remote data.",
//   "Loading backend services.",
//   "Caching static content.",
//   "Initializing API connections.",
//   "Fetching server assets.",
//   "Processing content updates.",
//   "Rendering dynamic data."
// ];
// const messages = [
//   "Loading… almost there.",
//   "Processing your request.",
//   "Updating system now.",
//   "Work in progress.",
//   "Fetching necessary data.",
//   "Configuring your setup.",
//   "Systems in motion.",
//   "Gathering required resources.",
//   "Almost ready for you.",
//   "Loading essential components.",
//   "Preparing your experience.",
//   "Processing with precision.",
//   "Activating necessary functions.",
//   "Synchronizing all systems.",
//   "Completing your setup.",
//   "Initiating system processes.",
//   "Finalizing your request.",
//   "Adjusting configurations now.",
//   "Loading key elements.",
//   "Making things happen.",
//   "Engaging system resources.",
//   "Completing your update.",
//   "Activating important features.",
//   "Preparing necessary updates.",
//   "Organizing data now.",
//   "Bringing it together.",
//   "Finalizing system details.",
//   "Setting up efficiently.",
//   "Configuring components fully.",
//   "Loading relevant data.",
//   "Synchronizing key systems.",
//   "Working on enhancements.",
//   "Adjusting settings now.",
//   "Processing essential updates.",
//   "Preparing final touches.",
//   "Loading essential functions.",
//   "Making adjustments now.",
//   "Gathering final resources.",
//   "Processing data swiftly.",
//   "Configuring your environment.",
//   "Setting up systems.",
//   "Loading core elements.",
//   "Completing system tasks.",
//   "Updating all components.",
//   "Engaging key processes.",
//   "Loading and syncing.",
//   "Preparing your platform.",
//   "Processing with efficiency.",
//   "Configuring for performance."
// ];
// const messages = [
//   "Optimizing your experience.",
//   "Enhancing system performance.",
//   "Processing your request.",
//   "Refining data efficiency.",
//   "Configuring for results.",
//   "Streamlining operations now.",
//   "Updating for improvements.",
//   "Balancing system resources.",
//   "Securing data integrity.",
//   "Analyzing performance metrics.",
//   "Fine-tuning for precision.",
//   "Enhancing operational functionality.",
//   "Validating system changes.",
//   "Optimizing resource usage.",
//   "Updating configurations swiftly.",
//   "Processing with care.",
//   "Configuring optimal settings.",
//   "Refining system operations.",
//   "Improving performance steadily.",
//   "Enhancing data processing.",
//   "Optimizing for results.",
//   "Updating settings promptly.",
//   "Refining your experience.",
//   "Balancing system demands.",
//   "Securing operations effectively.",
//   "Streamlining performance enhancements.",
//   "Analyzing system stability.",
//   "Processing with precision.",
//   "Configuring updates efficiently.",
//   "Optimizing resource allocation.",
//   "Enhancing functionality continuously.",
//   "Validating operational performance.",
//   "Refining settings for efficiency.",
//   "Updating system optimally.",
//   "Streamlining for success.",
//   "Enhancing operational outcomes.",
//   "Balancing performance demands.",
//   "Configuring data settings.",
//   "Processing updates seamlessly.",
//   "Optimizing system features.",
//   "Updating for efficiency.",
//   "Analyzing data improvements.",
//   "Enhancing system reliability.",
//   "Refining configurations thoroughly.",
//   "Securing data effectively.",
//   "Streamlining for precision.",
//   "Improving performance quickly.",
//   "Balancing updates efficiently.",
//   "Optimizing data usage."
// ];
const messages = ["Balancing the latest info… with care and commitment just for you.", "Balancing the latest info… with accuracy to enhance your experience.", "Balancing the latest info… with a focus on making things perfect for you.", "Balancing the latest info… with dedication to bring you the best.", "Balancing the latest info… with our full attention to your needs."
// "Exploring the vastness of data… to make your experience exceptional.",
// "Exploring the vastness of knowledge… with your needs in mind.",
// "Exploring the vastness of information… to deliver the best results for you.",
// "Exploring the vastness of intelligence… to enrich your experience.",
// "Processing the latest data… to ensure everything is just right for you.",
// "Processing the latest information… with your satisfaction as our goal.",
// "Processing the latest knowledge… to provide you with top-notch results.",
// "Processing the latest intelligence… to make your experience seamless.",
// "Analyzing the latest trends… to bring you valuable insights.",
// "Analyzing the latest patterns… to improve your experience.",
// "Analyzing the latest insights… with your needs at the forefront.",
// "Analyzing the latest data… to offer you the best possible results.",
// "Optimizing your experience… because you deserve the best.",
// "Optimizing your results… with your success in mind.",
// "Empowering your decisions… by delivering the most accurate information.",
// "Empowering your choices… with insights that matter to you.",
// "Empowering your actions… through careful analysis and precision.",
// "Empowering your strategies… by providing valuable and timely data.",
// "Navigating the complexities… to make your journey smoother.",
// "Navigating the challenges… with your needs as our top priority.",
// "Navigating the obstacles… to ensure a seamless experience for you.",
// "Navigating the unknown… with dedication to serve you better.",
// "Transforming your world… with thoughtful and careful work.",
// "Transforming your reality… by focusing on what matters most to you.",
// "Transforming your life… through detailed and accurate processing.",
// "Transforming your future… with every effort to exceed your expectations.",
// "Transforming your destiny… by providing the best service possible.",
// "Elevating your experience… with our commitment to excellence.",
// "Elevating your results… through dedicated and careful work.",
// "Elevating your outcomes… with our full attention to detail."
];
// const messages = [
//   "Balancing the latest info… with precision and purpose.",
//   "Balancing the latest info… with accuracy and agility.",
//   "Balancing the latest info… with clarity and conviction.",
//   "Balancing the latest info… with focus and finesse.",
//   "Balancing the latest info… with insight and integrity.",
//   "Exploring the vastness of data",
//   "Exploring the vastness of knowledge",
//   "Exploring the vastness of information",
//   "Exploring the vastness of intelligence",
//   "Processing the latest data…",
//   "Processing the latest information…",
//   "Processing the latest knowledge…",
//   "Processing the latest intelligence…",
//   "Analyzing the latest trends…",
//   "Analyzing the latest patterns…",
//   "Analyzing the latest insights…",
//   "Analyzing the latest data…",
//   "Optimizing your experience…",
//   "Optimizing your results…",
//   "Empowering your decisions…",
//   "Empowering your choices…",
//   "Empowering your actions…",
//   "Empowering your strategies…",
//   "Navigating the complexities…",
//   "Navigating the challenges…",
//   "Navigating the obstacles…",
//   "Navigating the unknown…",
//   "Transforming your world…",
//   "Transforming your reality…",
//   "Transforming your life…",
//   "Transforming your future…",
//   "Transforming your destiny…",
//   "Elevating your experience…",
//   "Elevating your results…",
//   "Elevating your outcomes…",
// ];

const Loader = () => {
  const classes = useStyles();

  // Access the activeRequests count from the root state
  const activeRequests = useSelector(state => state.loading?.activeRequests);

  // Local state to control whether the loader should be visible
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState('');
  useEffect(() => {
    let timer;
    if (activeRequests > 0) {
      setShowLoader(true); // Show the loader immediately when a request starts
      // Randomly select a message
      setMessage(messages[Math.floor(Math.random() * messages.length)]);
    } else if (activeRequests === 0 && showLoader) {
      // When requests end, ensure the loader stays visible for at least the minimum time
      timer = setTimeout(() => {
        setShowLoader(false); // Hide the loader after the minimum display time
      }, MINIMUM_DISPLAY_TIME);
    }

    // Cleanup the timer to avoid memory leaks
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [activeRequests, showLoader]);
  return <>
      {showLoader && <div className={classes.loaderContainer}>
          <Box className={classes.progressBar}>
            <LinearProgress />
          </Box>
          <Typography className={classes.message} variant="h6">
            {message}
          </Typography>
        </div>}
    </>;
};
export default Loader;