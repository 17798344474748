// loaderMiddleware.ts
const loaderMiddleware = (store) => (next) => async (action) => {
  const { dispatch } = store;

  // Check if the action type contains the word 'get' to show the loader
  if (action.type.toLowerCase().includes('get')) {
    dispatch({ type: 'SHOW_LOADER' });
  }

  // Proceed to the next middleware/reducer
  const result = await next(action);

  // If the action is async and ends with _SUCCESS or _FAILURE, hide the loader
  if (action.type.toLowerCase().includes('get') && (action.type.endsWith('_SUCCESS') || action.type.endsWith('_FAILURE'))) {
    dispatch({ type: 'HIDE_LOADER' });
  }

  return result;
};

export default loaderMiddleware;