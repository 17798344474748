import { createAction } from 'redux-actions'
import { emptyEvent, EventDate } from "./producerReducer"

import {
  AnyDispatchType,
  SET_EVENT,
  SET_EVENT_DATES,
  SET_EVENT_FLOORS,
  SET_EVENT_FLOOR_PANELS,
  SET_EVENT_REG_CODES,
  SET_EVENTS,
  SET_EVENT_PRICING,
  SET_EVENT_DIVISION_PRICING,
  SET_EVENT_REGISTRATION_LOGO
} from "lib/constants"

import { client } from "store/themisClient"
import themis_api from "store/themis_api_pb"
import themis_common, { ArchiveMap } from "store/themis_common_pb"
import { ObjectUserPermissions } from "store/program/programReducer"
import { buildEvent } from './producerBuilders'
import { eventDivisionSortFunction } from "lib/validators"

export interface AddEventOptions {
  name: string
  logo: string
  country: string
  state: string
  city: string
  address: string
  address2: string
  postalCode: string
  email: string
  phone: string
  website: string
  info: string
  currency: string
}
export interface IAddJudgeType {
  judgeId: number;
  producerId: number | undefined;
  eventJudgeShiftId: number | undefined;
  judgeTypeId: number | undefined;
}
export interface IDeleteJudgeType {
  judgeId: number | null | undefined;
  eventId: number | null | undefined;
  brandId: number | null | undefined;
  producerId: number | null | undefined;
  judgeTypeId: number | null | undefined;
}
export interface ISplitDivision {
  eventDivisions: {
    eventTeamId: number[];
    name: string | undefined;
}[];
producerId: number;
brandId: number;
eventId: number;
divisionId: number | null;
eventDivisionId: number;
rubricId: number;
nonCrossover: boolean;
shiftId: number;
}

export interface UpdateEventOptions {
  name: string
  logo: string
  country: string
  city: string
  state: string
  address: string
  address2: string
  postalCode: string
  email: string
  phone: string
  website: string
  info: string
  currency: string
}

interface EventPricing {
  athletePrice: string
  crossoverPrice: string
}

interface EventDivisionPricing {
  eventDivisionId: number
  athletePrice: string
  teamPrice: string
}
export interface DepositStructure {
  deposit: number;
  credit: number;
};
export interface IDeleteEventJudgeShiftByJudgeId {
  judgeId: number | null | undefined;
  producerId: number | null | undefined;
  brandId: number | null | undefined;
  eventId: number | null | undefined;
  eventFloorDateJudgingPanelShiftId: number | null | undefined;
};

const setEvents = createAction<themis_common.Event.AsObject[]>(SET_EVENTS)
const setEvent = createAction<number>(SET_EVENT)
const setEventDates = createAction<themis_common.EventDate.AsObject[]>(SET_EVENT_DATES)
const setEventRegCodes = createAction<themis_common.EventRegistrationCode.AsObject[]>(SET_EVENT_REG_CODES)
const setEventFloors = createAction<themis_common.EventFloor.AsObject[]>(SET_EVENT_FLOORS)
const setEventPricing = createAction<EventPricing>(SET_EVENT_PRICING)
const setEventDivisionPricing = createAction<EventDivisionPricing>(SET_EVENT_DIVISION_PRICING)
const setEventRegistrationLogo = createAction<string>(SET_EVENT_REGISTRATION_LOGO)
const setEventFloorPanels = createAction<{panels: themis_common.EventFloorDateJudgingPanel.AsObject[], eventFloorDateId: number }>(SET_EVENT_FLOOR_PANELS)

export const getEventDivisions = async (eventId: number, brandId: number, producerId: number): Promise<themis_common.EventDivision.AsObject[]> => {
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  const response: themis_api.EventDivisionResponse.AsObject = (await client.getEventDivisionsByEvent(event, {})).toObject()
  const eventDivisions = response.divisionsList
  eventDivisions.sort(eventDivisionSortFunction)
  return eventDivisions
}

export const getEventDivisionsThin = async (eventId: number, brandId: number, producerId: number): Promise<themis_common.EventDivision.AsObject[]> => {
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  const response: themis_api.EventDivisionResponse.AsObject = (await client.getEventDivisionsByEventThin(event, {})).toObject()
  const eventDivisions = response.divisionsList
  eventDivisions.sort(eventDivisionSortFunction)
  return eventDivisions
}
export const getEventDivisionUses = async (eventId: number, brandId: number, producerId: number): Promise<themis_common.EventDivisionUsage.AsObject[]> => {
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  const response: themis_api.EventDivisionUsageResponse.AsObject = (await client.getEventDivisionUsesByEvent(event, {})).toObject()
  return response.usagesList
}

export const getSeasons = async (eventDate: string): Promise<themis_common.Season.AsObject[]> => {
  const seasonRequest = new themis_api.GetSeasonsRequest()
  seasonRequest.setRequestedDate(eventDate)
  const response: themis_api.SeasonsResponse.AsObject = (await client.getSeasons(seasonRequest, {})).toObject()
  return response.seasonsList
}

export const deleteEvent = async (dispatch: AnyDispatchType, eventId: number, brandId: number, producerId: number): Promise<void> => {
  const eventRequest = new themis_common.Event()
  eventRequest.setId(eventId)
  eventRequest.setBrandId(brandId)
  eventRequest.setProducerId(producerId)
  const response: themis_api.EventResponse.AsObject = (await client.deleteEvent(eventRequest, {})).toObject()
  dispatch(setEvents(response.eventsList))
}

export const getEvents = async (dispatch: AnyDispatchType, brandId: number, producerId: number) => {
  if (brandId > 0 && producerId > 0) {
    const brandRequest = new themis_common.Brand()
    brandRequest.setProducerId(producerId)
    brandRequest.setId(brandId)
    const response: themis_api.EventResponse.AsObject = (await client.getEvents(brandRequest, {})).toObject()
    dispatch(setEvents(response.eventsList))
    return response.eventsList
  }
}

export const getEvent = async (eventId: number): Promise<themis_common.Event.AsObject> => {
  if (!eventId) return emptyEvent

  const event = new themis_common.Event()
  event.setId(eventId)
  const response: themis_api.EventResponse.AsObject = (await client.getOneEvent(event, {})).toObject()
  if (response.event) {
    console.log("response.event", response.event)
    return response.event
  } else {
    return emptyEvent
  }
}

export const getCurrentEvent = async(eventId:number | null)=> {
  if (!eventId) return emptyEvent;
  const currentEvent = new themis_api.EventIdRequest();
  currentEvent.setEventId(eventId);
  const response: themis_api.ProgramResponse.AsObject = (await client.getEventPublic(currentEvent, {})).toObject();
  return response;
} 

export const addEvent = async (dispatch: AnyDispatchType, eventObject: themis_common.Event.AsObject): Promise<number> => {
  const eventRequest = buildEvent(eventObject)
  const response: themis_api.EventResponse.AsObject = (await client.addEvent(eventRequest, {})).toObject()

  if (response.event && response.event.id > 0) {
    dispatch(setEvents(response.eventsList))
    dispatch(setEvent(response.event.id))
    return response.event.id
  }
  throw new Error("Error adding event")
}

export const addEventLogo = async(dispatch: AnyDispatchType, eventId: number, image: string, fileName: string): Promise<themis_api.ProgramResponse.AsObject> => {
  const addLogoRequest = new themis_common.AddLogoRequest()
  addLogoRequest.setId(eventId)
  addLogoRequest.setImage(image)
  addLogoRequest.setFileName(fileName)
  const response: themis_api.ProgramResponse.AsObject = (await client.addEventLogo(addLogoRequest, {})).toObject()
  await doSetEvent(dispatch, eventId)
  return response
}

export const getDeposit = async (eventTeamId: number): Promise<DepositStructure> => {
  const eventTeam = new themis_common.EventTeam()
  eventTeam.setId(eventTeamId)
  const response: themis_api.GetCurrentDepositResponse.AsObject = (await client.getCurrentDeposit(eventTeam, {})).toObject()

  return { deposit: response.deposit, credit: response.credit }
}

export const updateEvent = async (dispatch: AnyDispatchType, eventObject: themis_common.Event.AsObject): Promise<void> => {
  const eventRequest = buildEvent(eventObject)
  const response: themis_api.EventResponse.AsObject = (await client.updateEvent(eventRequest, {})).toObject()
  dispatch(setEvents(response.eventsList))
}

export const doSetEvent = async (dispatch: AnyDispatchType, eventId: number): Promise<void> => {
  if (eventId > 0) {
    dispatch(setEvent(eventId))
  }
}

export const clearEvent = async (dispatch: AnyDispatchType): Promise<void> => {
  dispatch(setEvent(-1))
}

export const doSetEventRegCodes = async (dispatch: AnyDispatchType, registrationCodesList: any[]): Promise<void> => {
  dispatch(setEventRegCodes(registrationCodesList))
}

export const doSetEventFloors = async (dispatch: AnyDispatchType, eventFloors: any[]): Promise<void> => {
  dispatch(setEventFloors(eventFloors))
}

export const doSetEventRegistrationLogo = async (dispatch: AnyDispatchType, logo: string): Promise<void> => {
  dispatch(setEventRegistrationLogo(logo))
}

export const formatEventDates = (dates: themis_common.EventDate.AsObject[]): EventDate[] => {
  const outDates: EventDate[] = []
  if (dates && dates.length) {

    dates.sort((a, b) => {
      if (!a.startClockTime?.unixTime) return -1
      if (!b.startClockTime?.unixTime) return 1
      if (a.startClockTime.unixTime > b.startClockTime.unixTime) return 1
      return -1
    })

    dates.forEach((date) => {
      const newDate: EventDate = {
        id: date.id,
        start: date.startDay,
        end: date.endDay
      }
      outDates.push(newDate)
    })
  }
  return outDates
}

export const addDivisionToEvent = async (divisionId: number, eventId: number, brandId: number, producerId: number): Promise<void> => {
  const eventDivision = new themis_common.EventDivision()
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  eventDivision.setEvent(event)
  const division = new themis_common.Division()
  division.setId(divisionId)
  eventDivision.setDivision(division)
  await client.addEventDivisions(eventDivision, {})
}

export const updateEventDivision = async (eventDivisionId: number, eventId: number, brandId: number, producerId: number, nonCrossover: boolean, name?: string): Promise<void> => {
  const eventDivision = new themis_common.EventDivision()
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  eventDivision.setEvent(event)
  eventDivision.setNonCrossover(nonCrossover)
  eventDivision.setId(eventDivisionId)
  if (name) eventDivision.setName(name)
  await client.updateEventDivision(eventDivision, {})
}

export const removeDivisionFromEvent = async (eventDivisionId: number, divisionId: number, eventId: number, brandId: number, producerId: number): Promise<void> => {
  const eventDivision = new themis_common.EventDivision()
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  eventDivision.setEvent(event)
  const division = new themis_common.Division()
  division.setId(divisionId)
  eventDivision.setDivision(division)
  eventDivision.setId(eventDivisionId)
  await client.deleteEventDivisions(eventDivision, {})
}

export const addDivisionsToEvent = async (divisions: themis_common.Division.AsObject[], eventId: number, brandId: number, producerId: number): Promise<void> => {
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  const eventDivision = new themis_common.EventDivision()
  eventDivision.setEvent(event)
  divisions.forEach((thedivision) => {
    const division = new themis_common.Division()
    division.setId(thedivision.id || 0)
    eventDivision.addDivisions(division)
  })
  await client.addEventDivisions(eventDivision, {})
}

export const removeDivisionsFromEvent = async (divisions: themis_common.Division.AsObject[], eventId: number, brandId: number, producerId: number): Promise<void> => {
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  const eventDivision = new themis_common.EventDivision()
  eventDivision.setEvent(event)
  divisions.forEach((theDivision) => {
    const division = new themis_common.Division()
    division.setId(theDivision.id)
    eventDivision.addDivisions(division)
  })
  await client.deleteEventDivisions(eventDivision, {})
}

export const addEventDate = async (dispatch: AnyDispatchType, eventId: number, brandId: number, producerId: number, dateStart: string, dateEnd: string): Promise<void> => {
  const addEventDateRequest = new themis_common.EventDate()
  addEventDateRequest.setEventId(eventId)
  addEventDateRequest.setBrandId(brandId)
  addEventDateRequest.setProducerId(producerId)
  addEventDateRequest.setStartDay(dateStart)
  addEventDateRequest.setEndDay(dateEnd)
  const response: themis_api.EventDatesResponse.AsObject = (await client.addEventDate(addEventDateRequest, {})).toObject()
  dispatch(setEventDates(response.eventDatesList))
  dispatch(setEventFloors(response.eventFloorsList))
}

export const deleteEventDate = async (dispatch: AnyDispatchType, eventDateId: number, producerId: number, brandId: number, eventId: number): Promise<void> => {
  const deleteEventDateRequest = new themis_common.EventDate()
  deleteEventDateRequest.setProducerId(producerId)
  deleteEventDateRequest.setBrandId(brandId)
  deleteEventDateRequest.setEventId(eventId)
  deleteEventDateRequest.setId(eventDateId)
  const response: themis_api.EventDatesResponse.AsObject = (await client.deleteEventDate(deleteEventDateRequest, {})).toObject()
  dispatch(setEventDates(response.eventDatesList))
  dispatch(setEventFloors(response.eventFloorsList))
}

export const addEventFloor = async (dispatch: AnyDispatchType, eventId: number, brandId: number, producerId: number, name: string): Promise<void> => {
  const addEventFloorRequest = new themis_common.EventFloor()
  addEventFloorRequest.setEventId(eventId)
  addEventFloorRequest.setBrandId(brandId)
  addEventFloorRequest.setProducerId(producerId)
  addEventFloorRequest.setName(name)
  const response: themis_api.EventFloorsResponse.AsObject = (await client.addEventFloor(addEventFloorRequest, {})).toObject()
  dispatch(setEventFloors(response.eventFloorsList))
}

export const deleteEventFloor = async (dispatch: AnyDispatchType, eventFloorId: number, producerId: number, brandId: number, eventId: number): Promise<void> => {
  const deleteEventFloorRequest = new themis_common.EventFloor()
  deleteEventFloorRequest.setProducerId(producerId)
  deleteEventFloorRequest.setBrandId(brandId)
  deleteEventFloorRequest.setEventId(eventId)
  deleteEventFloorRequest.setId(eventFloorId)
  const response: themis_api.EventFloorsResponse.AsObject = (await client.deleteEventFloor(deleteEventFloorRequest, {})).toObject()
  dispatch(setEventFloors(response.eventFloorsList))
}

export const sortEventFloor = async (dispatch: AnyDispatchType, eventFloorIds: number[], producerId: number, brandId: number, eventId: number): Promise<void> => {
  const sortEventFloorsRequest = new themis_api.SortFloorsRequest()
  sortEventFloorsRequest.setProducerId(producerId)
  sortEventFloorsRequest.setBrandId(brandId)
  sortEventFloorsRequest.setEventId(eventId)
  sortEventFloorsRequest.setIdsList(eventFloorIds)
  const response: themis_api.EventFloorsResponse.AsObject = (await client.sortEventFloors(sortEventFloorsRequest, {})).toObject()
  dispatch(setEventFloors(response.eventFloorsList))
}

export const addEventRegCode = async (dispatch: AnyDispatchType, name: string, code: string, discountType: themis_common.UnitTypeMap[keyof themis_common.UnitTypeMap], amt: string, valid:string, expires:string, discountPerType: string, eventId: number, brandId: number, producerId:number, depositType: string, depositAmount: number, isDefault: boolean, isCrossover: boolean, discountAppliesTo: string, eventDivisionIds: number[]): Promise<void> => {
  const addEventCodeRequest = new themis_common.EventRegistrationCode()
  addEventCodeRequest.setEventId(eventId)
  addEventCodeRequest.setBrandId(brandId)
  addEventCodeRequest.setProducerId(producerId)
  addEventCodeRequest.setName(name)
  addEventCodeRequest.setCode(code)
  addEventCodeRequest.setDiscountType(discountType)
  addEventCodeRequest.setDiscountPerType(discountPerType)
  addEventCodeRequest.setAmountString(amt)
  addEventCodeRequest.setBeginsOn(valid)
  addEventCodeRequest.setExpiresOn(expires)
  addEventCodeRequest.setDefault(isDefault)
  addEventCodeRequest.setDepositType(depositType)
  addEventCodeRequest.setDepositAmount(depositAmount)
  addEventCodeRequest.setCrossovers(isCrossover)
  addEventCodeRequest.setDiscountAppliesTo(discountAppliesTo)
  if (discountAppliesTo === 'DivisionList') {
    const eventDivisions: themis_common.EventDivision[] = []
    eventDivisionIds.forEach((divisionId) => {
      const eventDivision = new themis_common.EventDivision()
      eventDivision.setId(divisionId)
      eventDivisions.push(eventDivision)
    })
    addEventCodeRequest.setEventDivisionsList(eventDivisions)
  }
  const response: themis_api.EventRegistrationCodesResponse.AsObject = (await client.addEventRegistrationCode(addEventCodeRequest, {})).toObject()
  dispatch(setEventRegCodes(response.registrationCodesList))
}

export const updateEventRegCode = async (dispatch: AnyDispatchType, codeId: number, name: string, code: string, discountType: themis_common.UnitTypeMap[keyof themis_common.UnitTypeMap], amt: string, valid:string, expires:string, discountPerType: string, eventId: number, brandId: number, producerId:number, depositType: string, depositAmount: number, isDefault: boolean, isCrossover: boolean, discountAppliesTo: string, eventDivisionIds: number[]): Promise<void> => {
  const updateEventRegCodeRequest = new themis_common.EventRegistrationCode()
  updateEventRegCodeRequest.setId(codeId)
  updateEventRegCodeRequest.setEventId(eventId)
  updateEventRegCodeRequest.setBrandId(brandId)
  updateEventRegCodeRequest.setProducerId(producerId)
  updateEventRegCodeRequest.setName(name)
  updateEventRegCodeRequest.setCode(code)
  updateEventRegCodeRequest.setDiscountType(discountType)
  updateEventRegCodeRequest.setDiscountPerType(discountPerType)
  updateEventRegCodeRequest.setAmountString(amt)
  updateEventRegCodeRequest.setBeginsOn(valid)
  updateEventRegCodeRequest.setExpiresOn(expires)
  updateEventRegCodeRequest.setDefault(isDefault)
  updateEventRegCodeRequest.setDepositType(depositType)
  updateEventRegCodeRequest.setDepositAmount(depositAmount)
  updateEventRegCodeRequest.setCrossovers(isCrossover)
  updateEventRegCodeRequest.setDiscountAppliesTo(discountAppliesTo)

  if (discountAppliesTo === 'DivisionList') {
    const eventDivisions: themis_common.EventDivision[] = []
    eventDivisionIds.forEach((divisionId) => {
      const eventDivision = new themis_common.EventDivision()
      eventDivision.setId(divisionId)
      eventDivisions.push(eventDivision)
    })
    updateEventRegCodeRequest.setEventDivisionsList(eventDivisions)
  }

  const response: themis_api.EventRegistrationCodesResponse.AsObject = (await client.updateEventRegistrationCode(updateEventRegCodeRequest, {})).toObject()
  dispatch(setEventRegCodes(response.registrationCodesList))

}

export const deleteEventRegCode = async (dispatch: AnyDispatchType, eventCodeId: number, producerId: number, brandId: number, eventId: number): Promise<void> => {
  const deleteEventCodeRequest = new themis_common.EventRegistrationCode()
  deleteEventCodeRequest.setId(eventCodeId)
  deleteEventCodeRequest.setEventId(eventId)
  deleteEventCodeRequest.setBrandId(brandId)
  deleteEventCodeRequest.setProducerId(producerId)

  const response: themis_api.EventRegistrationCodesResponse.AsObject = (await client.deleteEventRegistrationCode(deleteEventCodeRequest, {})).toObject()
  dispatch(setEventRegCodes(response.registrationCodesList))
}

export async function getApprovedEvents(programId: number): Promise<themis_common.Event.AsObject[]> {
  const program = new themis_common.Program()
  program.setId(programId)
  const request = new themis_api.EventGridRequest()
  request.setProgram(program)
  const returnEvents: themis_api.EventResponse.AsObject = (await client.getApprovedEvents(request, {})).toObject()
  return returnEvents.eventsList
}

export async function getEventUsers(eventId: number, brandId: number, producerId: number): Promise<ObjectUserPermissions[]> {
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  const response: themis_api.UserPermissionsResponse.AsObject = (await client.getEventUsers(event, {})).toObject()
  return response["usersPermissionsList"] as ObjectUserPermissions[]
}

export async function addEventPricing(eventId: number, brandId: number, producerId: number, athletePrice: string, crossoverPrice: string): Promise<void> {
  const eventPricing = new themis_common.EventPricing()
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setProducerId(producerId)
  event.setBrandId(brandId)
  eventPricing.setEvent(event)
  if (athletePrice) eventPricing.setAthletePriceString(athletePrice)
  if (crossoverPrice) eventPricing.setCrossoverPriceString(crossoverPrice)
  await client.addEventPricing(eventPricing, {})
}

export async function addEventDivisionPricing(eventDivisionId: number, eventId: number, brandId: number, producerId: number, athletePrice: string, teamPrice: string): Promise<void> {
  const eventDivisionPricing = new themis_common.EventDivisionPricing()
  const eventDivision = new themis_common.EventDivision()
  eventDivision.setId(eventDivisionId)
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setProducerId(producerId)
  event.setBrandId(brandId)
  eventDivision.setEvent(event)
  eventDivisionPricing.setEventDivision(eventDivision)
  eventDivisionPricing.setAthletePriceString(athletePrice)
  eventDivisionPricing.setTeamPriceString(teamPrice)
  await client.addEventDivisionPricing(eventDivisionPricing, {})
}

export async function addEventDivisionPricingBulk(eventDivisionPricings: themis_common.EventDivisionPricing[]): Promise<void> {
  const addEventDivisionPricingBulkRequest = new themis_api.AddEventDivisionPricingBulkRequest()
  addEventDivisionPricingBulkRequest.setEventDivisionPricingsList(eventDivisionPricings)
  await client.addEventDivisionPricingBulk(addEventDivisionPricingBulkRequest, {})
}

export async function setEventRegistrationLive(eventId: number, brandId: number, producerId: number, isLive: boolean, dispatch: AnyDispatchType): Promise<void> {
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  event.setRegistrationLive(isLive)
  const response = (await client.eventRegistrationLive(event, {})).toObject()
  dispatch(setEvents(response.eventsList))
}

export async function updatePricing(athletePrice: string, crossoverPrice: string, dispatch: AnyDispatchType): Promise<void> {
  dispatch(setEventPricing({ athletePrice: athletePrice, crossoverPrice: crossoverPrice }))
}

export async function updateEventDivisionPricing(eventDivisionId: number, athletePrice: string, teamPrice: string, dispatch: AnyDispatchType): Promise<void> {
  dispatch(setEventDivisionPricing({ eventDivisionId: eventDivisionId, athletePrice: athletePrice, teamPrice: teamPrice }))
}

export async function getRegistrationActivityLogByEvent(eventId: number): Promise<themis_common.RegistrationActivityLogItem.AsObject[]> {
  const request = new themis_api.RegistrationActivityLogsByEventRequest()
  request.setEventId(eventId)
  const response: themis_api.RegistrationActivityLogsResponse.AsObject = (await client.registrationActivityLogByEvent(request, {})).toObject()
  return response.logsList
}

export async function getRubricsByProducerId(producerId: number): Promise<themis_common.Rubric.AsObject[]> {
  const request = new themis_common.Producer()
  request.setId(producerId)
  const response: themis_common.Rubrics.AsObject = (await client.getRubricsByProducerId(request, {})).toObject()
  return response.itemsList
}

export async function editFloorDate(day: themis_common.EventFloorDate.AsObject, producerId: number, brandId: number, eventId: number, dispatch: AnyDispatchType): Promise<themis_common.EventFloor.AsObject[]> {
  const floorDate = new themis_common.EventFloorDate()
  floorDate.setId(day.id)
  floorDate.setActive(day.active)
  floorDate.setStartDayTime(day.startDayTime)
  floorDate.setEndDayTime(day.endDayTime)
  floorDate.setProducerId(producerId)
  floorDate.setBrandId(brandId)
  floorDate.setEventId(eventId)
  floorDate.setRotationTime(day.rotationTime)
  floorDate.setWarmUpTime(day.warmUpTime)
  const response: themis_api.EventFloorsResponse.AsObject = (await client.editEventFloorDate(floorDate, {})).toObject()
  dispatch(setEventFloors(response.eventFloorsList))
  return response.eventFloorsList
}

export const addPanel = async (dispatch: AnyDispatchType, activeDay: themis_common.EventFloorDate.AsObject, thePanelName: string): Promise<void> => {
  const floorDatePanel = new themis_common.EventFloorDateJudgingPanel()
  floorDatePanel.setName(thePanelName)
  floorDatePanel.setEventFloorDateId(activeDay.id)
  const response: themis_common.EventFloorDateJudgingPanelResponse.AsObject = (await client.addEventFloorDateJudgingPanel(floorDatePanel, {})).toObject()
  if (response?.panelsList?.length > 0) {
    dispatch(setEventFloorPanels({ panels: response.panelsList, eventFloorDateId: activeDay.id }))
  }
}

export const copyFromPreviousDay = async (dispatch: AnyDispatchType, activeDayId: number, prevDateId: number): Promise<void> => {
  // This copies panels,
  const floorDate = new themis_common.EventFloorDate()
  floorDate.setId(activeDayId)
  floorDate.setEventDateId(prevDateId)
  const response: themis_common.EventFloorDateJudgingPanelResponse.AsObject = (await client.copyPreviousFloorDate(floorDate, {})).toObject()
  if (response?.panelsList?.length > 0) {
    dispatch(setEventFloorPanels({ panels: response.panelsList, eventFloorDateId: activeDayId }))
  }
}

export const addPanelJudgeShift = async (dispatch: AnyDispatchType, activeDay: themis_common.EventFloorDate.AsObject, activePanel: themis_common.EventFloorDateJudgingPanel.AsObject, shiftStart: string, shiftEnd: string,isBreak:boolean,breakDescription:string | null): Promise<void> => {
  const eventFloorDatePanelShift = new themis_common.EventFloorDateJudgingPanelShift()
  eventFloorDatePanelShift.setEventFloorDateJudgingPanelId(activePanel.id)
  eventFloorDatePanelShift.setStartTs(shiftStart)
  eventFloorDatePanelShift.setEndTs(shiftEnd);
  eventFloorDatePanelShift.setIsBreak(isBreak)
  eventFloorDatePanelShift.setBreakDescription(breakDescription as string);
  const response: themis_common.EventFloorDateJudgingPanelResponse.AsObject = (await client.addEventFloorDateJudgingPanelShift(eventFloorDatePanelShift, {})).toObject();
  if (response?.panelsList?.length > 0) {
    dispatch(setEventFloorPanels({ panels: response.panelsList, eventFloorDateId: activeDay.id }))
  }
}

export const editPanelJudgeShift = async (dispatch: AnyDispatchType, activeDay: themis_common.EventFloorDate.AsObject, activePanel: themis_common.EventFloorDateJudgingPanel.AsObject, activeShift: themis_common.EventFloorDateJudgingPanelShift.AsObject, shiftStart: string, shiftEnd: string,isBreak:boolean,breakDescription:string | null): Promise<void> => {
  const eventFloorDatePanelShift = new themis_common.EventFloorDateJudgingPanelShift()
  eventFloorDatePanelShift.setId(activeShift.id)
  eventFloorDatePanelShift.setEventFloorDateJudgingPanelId(activePanel.id)
  eventFloorDatePanelShift.setStartTs(shiftStart)
  eventFloorDatePanelShift.setEndTs(shiftEnd)
  eventFloorDatePanelShift.setIsBreak(isBreak);
  eventFloorDatePanelShift.setBreakDescription(breakDescription as string);
  const response: themis_common.EventFloorDateJudgingPanelResponse.AsObject = (await client.updateEventFloorDateJudgingPanelShift(eventFloorDatePanelShift, {})).toObject()
  if (response?.panelsList?.length > 0) {
    dispatch(setEventFloorPanels({ panels: response.panelsList, eventFloorDateId: activeDay.id }))
  }
}

export const deletePanelJudgeShift = async (dispatch: AnyDispatchType, activeDay: themis_common.EventFloorDate.AsObject, shiftId: number, panelId: number): Promise<void> => {
  const eventFloorDatePanelShift = new themis_common.EventFloorDateJudgingPanelShift()
  eventFloorDatePanelShift.setId(shiftId)
  eventFloorDatePanelShift.setEventFloorDateJudgingPanelId(panelId)
  const response: themis_common.EventFloorDateJudgingPanelResponse.AsObject = (await client.deleteEventFloorDateJudgingPanelShift(eventFloorDatePanelShift, {})).toObject()
  if (response?.panelsList?.length > 0) {
    dispatch(setEventFloorPanels({ panels: response.panelsList, eventFloorDateId: activeDay.id }))
  }
}

export const addPanelJudgeShiftDivision = async (dispatch: AnyDispatchType, activeDay: themis_common.EventFloorDate.AsObject, shiftId: number, eventDivisionId: number, toIndex: number): Promise<void> => {
  const request = new themis_api.EventFloorDateJudgingPanelShiftDivisionRequest()
  request.setEventDivisionId(eventDivisionId)
  request.setEventFloorDateJudgingPanelShiftId(shiftId)
  request.setSortOrder(toIndex)
  const response: themis_common.EventFloorDateJudgingPanelResponse.AsObject = (await client.addEventFloorDateJudgingPanelShiftDivision(request, {})).toObject()
  if (response?.panelsList?.length > 0) {
    dispatch(setEventFloorPanels({ panels: response.panelsList, eventFloorDateId: activeDay.id }))
  }
}

export const sortPanelJudgeShiftDivision = async (dispatch: AnyDispatchType, activeDay: themis_common.EventFloorDate.AsObject, shiftId: number, eventDivisionId: number, newIndex: number): Promise<void> => {
  const request = new themis_api.EventFloorDateJudgingPanelShiftDivisionRequest()
  request.setEventDivisionId(eventDivisionId)
  request.setEventFloorDateJudgingPanelShiftId(shiftId)
  request.setSortOrder(newIndex)
  const response: themis_common.EventFloorDateJudgingPanelResponse.AsObject = (await client.sortEventFloorDateJudgingPanelShiftDivision(request, {})).toObject()
  if (response?.panelsList?.length > 0) {
    dispatch(setEventFloorPanels({ panels: response.panelsList, eventFloorDateId: activeDay.id }))
  }
}

export const sortEventDivisionShiftTeam = async (dispatch: AnyDispatchType, activeDay: themis_common.EventFloorDate.AsObject, shiftId: number, divisionId: number, teamId: number, newIndex: number, eventId: number, producerId: number, brandId: number): Promise<void> => {
  const request = new themis_api.SortEventDivisionShiftTeamRequest()
  request.setShiftId(shiftId)
  request.setEventDivisionId(divisionId)
  request.setTeamId(teamId)
  request.setOrder(newIndex)
  request.setEventId(eventId);
  request.setProducerId(producerId);
  request.setBrandId(brandId);
  return (await client.sortEventDivisionShiftTeam(request, {})).toObject()
}

export const deletePanelJudgeShiftDivision = async (dispatch: AnyDispatchType, activeDay: themis_common.EventFloorDate.AsObject, shiftId: number, eventDivisionId: number): Promise<void> => {
  const request = new themis_api.EventFloorDateJudgingPanelShiftDivisionRequest()
  request.setEventDivisionId(eventDivisionId)
  request.setEventFloorDateJudgingPanelShiftId(shiftId)
  const response: themis_common.EventFloorDateJudgingPanelResponse.AsObject = (await client.deleteEventFloorDateJudgingPanelShiftDivision(request, {})).toObject()
  if (response?.panelsList?.length > 0) {
    dispatch(setEventFloorPanels({ panels: response.panelsList, eventFloorDateId: activeDay.id }))
  }
}

export const delPanel = async (dispatch: AnyDispatchType, activeDay: themis_common.EventFloorDate.AsObject, thePanelId: number): Promise<void> => {
  const floorDatePanel = new themis_common.EventFloorDateJudgingPanel()
  floorDatePanel.setId(thePanelId)
  floorDatePanel.setEventFloorDateId(activeDay.id)
  const response: themis_common.EventFloorDateJudgingPanelResponse.AsObject = (await client.deleteEventFloorDateJudgingPanel(floorDatePanel, {})).toObject()
  dispatch(setEventFloorPanels({ panels: response.panelsList, eventFloorDateId: activeDay.id }))
}

export const splitEventDivisions = async (
  splitDivisionPayload: ISplitDivision
) => {
  return async (dispatch:AnyDispatchType):Promise<void>=>{
    const {
      brandId,
      divisionId,
      eventDivisionId,
      eventDivisions,
      eventId,
      producerId,
      rubricId,
      nonCrossover,
      shiftId,
    } = splitDivisionPayload;
    const splitDivisionRequest = new themis_api.SplitEventDivisionRequest();
    const splitDivisionId = divisionId as number;
    splitDivisionRequest.setBrandId(brandId);
    splitDivisionRequest.setDivisionId(splitDivisionId);
    splitDivisionRequest.setProducerId(producerId);
    splitDivisionRequest.setEventDivisionId(eventDivisionId);
    splitDivisionRequest.setRubricId(rubricId);
    splitDivisionRequest.setNonCrossover(nonCrossover);
    splitDivisionRequest.setShiftId(shiftId);
    splitDivisionRequest.setEventId(eventId);
    const eventDivisionList:themis_api.NewEventDivision[] = [];
    eventDivisions.forEach(({name,eventTeamId})=> {
      const newEventDivision: themis_api.NewEventDivision = new themis_api.NewEventDivision();
        newEventDivision.setName(name as string);
        newEventDivision.setEventTeamIdList(eventTeamId as number[]);
        eventDivisionList.push(newEventDivision);
    });
    splitDivisionRequest.setEventDivisionsList(eventDivisionList);
    await client.splitEventDivision(splitDivisionRequest, {});
    // Get Division API Calling After Split Divisions
    await getEvents(dispatch,brandId,producerId);
  };
};

export async function getEventEmailCounts(eventId: number, brandId: number, producerId: number): Promise<themis_api.CountEventEmailsResponse.AsObject> {
  const request = new themis_common.Event()
  request.setId(eventId)
  request.setBrandId(brandId)
  request.setProducerId(producerId)
  const response: themis_api.CountEventEmailsResponse.AsObject = (await client.countEventEmails(request, {})).toObject()
  return response
}

export async function sendEventDocumentEmails(eventId: number, brandId: number, producerId: number): Promise<void> {
  const request = new themis_common.Event()
  request.setId(eventId)
  request.setBrandId(brandId)
  request.setProducerId(producerId)
  await client.sendEventEmails(request, {})
}

export const getEventDocuments = async (producerId: number, brandId: number, eventId: number): Promise<themis_common.EventDocument.AsObject[]> => {
  const eventDocument = new themis_common.EventDocument()
  eventDocument.setEventId(eventId)
  eventDocument.setBrandId(brandId)
  eventDocument.setProducerId(producerId)
  const response: themis_api.DocumentResponse.AsObject = (await client.getEventDocuments(eventDocument, {})).toObject()
  return response.documentsList
}

export const saveEventDocument = async (document: themis_common.EventDocument.AsObject, producerId: number, brandId: number, eventId: number): Promise<themis_common.EventDocument.AsObject | undefined> => {
  const eventDocument = new themis_common.EventDocument()
  eventDocument.setId(document.id)
  eventDocument.setProducerId(producerId)
  eventDocument.setBrandId(brandId)
  eventDocument.setEventId(eventId)
  eventDocument.setName(document.name)
  eventDocument.setText(document.text)
  eventDocument.setStatus(document.status)

  const response: themis_api.DocumentResponse.AsObject = (await client.editEventDocument(eventDocument, {})).toObject()
  return response?.eventDocument
}

export const addEventDocument = async (document: themis_common.EventDocument.AsObject, producerId: number, brandId: number, eventId: number): Promise<themis_common.EventDocument.AsObject | undefined> => {
  const eventDocument = new themis_common.EventDocument()
  eventDocument.setProducerId(producerId)
  eventDocument.setBrandId(brandId)
  eventDocument.setEventId(eventId)
  eventDocument.setName(document.name)
  eventDocument.setText(document.text)
  eventDocument.setStatus(document.status)

  const response: themis_api.DocumentResponse.AsObject = (await client.addEventDocument(eventDocument, {})).toObject()
  return response?.eventDocument
}

export const resendDocumentToEvent = async (document: themis_common.EventDocument.AsObject, producerId: number, brandId: number, eventId: number): Promise<void> => {
  const event = new themis_common.Event()
  event.setProducerId(producerId)
  event.setBrandId(brandId)
  event.setId(eventId)

  await client.resendDocumentToEvent(event, {})
}

export const deleteEventDocument = async (document: themis_common.EventDocument.AsObject, producerId: number, brandId: number, eventId: number): Promise<themis_common.EventDocument.AsObject | undefined> => {
  const eventDocument = new themis_common.EventDocument()
  eventDocument.setId(document.id)
  eventDocument.setProducerId(producerId)
  eventDocument.setBrandId(brandId)
  eventDocument.setEventId(eventId)

  const response: themis_api.DocumentResponse.AsObject = (await client.deleteEventDocument(eventDocument, {})).toObject()
  return response?.eventDocument
}

export const mergeEventDivision = async(dispatch: AnyDispatchType, divisionName: string, divisionIdList: number[] | [], eventId: number, producerId: number, brandId: number, shiftId: number) => {
  const eventDivision = new themis_api.MergeDivisionRequest()
  eventDivision.setName(divisionName)
  eventDivision.setEventDivisionIdList(divisionIdList)
  eventDivision.setEventId(eventId)
  eventDivision.setBrandId(brandId)
  eventDivision.setProducerId(producerId)
  eventDivision.setShiftId(shiftId)
  await client.mergeEventDivision(eventDivision, {})
}

export const getSignedEventDocuments = async (document: themis_common.EventDocument.AsObject, producerId: number, brandId: number, eventId: number): Promise<themis_api.EventDocumentResponse.AsObject | undefined> => {
  const eventDocument = new themis_common.EventDocument()
  eventDocument.setId(document.id)
  eventDocument.setProducerId(producerId)
  eventDocument.setBrandId(brandId)
  eventDocument.setEventId(eventId)

  const response: themis_api.EventDocumentResponse.AsObject = (await client.getSignedEventDocuments(eventDocument, {})).toObject()
  return response
}

export const addEventJudgeShifts = async(judgesToAdd: { id: number, name: string }[], panelId: number, panelShiftId: number, producerId: number, brandId: number, eventId: number): Promise<themis_common.EventJudgeShift.AsObject[]> => {
  const eventJudgeShiftRequest = new themis_common.EventJudgeShiftRequest()

  const judges: themis_common.Judge[] = []

  judgesToAdd.forEach((judgeToAdd) => {
    const judge = new themis_common.Judge()
    judge.setId(judgeToAdd.id)
    judge.setName(judgeToAdd.name)
    judges.push(judge)
  })
  eventJudgeShiftRequest.setEventFloorDateJudgingPanelShiftId(panelShiftId)
  eventJudgeShiftRequest.setJudgesList(judges)
  eventJudgeShiftRequest.setProducerId(producerId)
  eventJudgeShiftRequest.setBrandId(brandId)
  eventJudgeShiftRequest.setEventId(eventId)

  const response: themis_common.EventJudgeShiftResponse = await client.addEventJudgeShifts(eventJudgeShiftRequest, {})
  const res = response.toObject()
  return res.eventJudgeShiftsList
}

export const getEventJudgeShifts = async(panelShiftId: number, producerId: number, brandId: number, eventId: number): Promise<themis_common.EventJudgeShift.AsObject[]> => {
  const eventJudgeShiftRequest = new themis_common.EventJudgeShiftRequest()
  eventJudgeShiftRequest.setEventFloorDateJudgingPanelShiftId(panelShiftId)
  eventJudgeShiftRequest.setProducerId(producerId)
  eventJudgeShiftRequest.setBrandId(brandId)
  eventJudgeShiftRequest.setEventId(eventId)

  const response: themis_common.EventJudgeShiftResponse = await client.getEventJudgeShifts(eventJudgeShiftRequest, {})
  const res = response.toObject()
  return res.eventJudgeShiftsList
}

export const deleteEventJudgeShift = async(eventJudgeShiftId: number, panelShiftId: number, producerId: number, brandId: number, eventId: number): Promise<themis_common.EventJudgeShift.AsObject[]> => {
  const eventJudgeShiftRequest = new themis_common.EventJudgeShiftRequest()

  eventJudgeShiftRequest.setId(eventJudgeShiftId)
  eventJudgeShiftRequest.setEventFloorDateJudgingPanelShiftId(panelShiftId)
  eventJudgeShiftRequest.setProducerId(producerId)
  eventJudgeShiftRequest.setBrandId(brandId)
  eventJudgeShiftRequest.setEventId(eventId)

  const response: themis_common.EventJudgeShiftResponse = await client.deleteEventJudgeShift(eventJudgeShiftRequest, {})
  const res = response.toObject()
  return res.eventJudgeShiftsList
}

export const generateTestTeamsForEvent = async (eventId: number, count: number): Promise<void> => {
  const request = new themis_api.TeamGenerationRequest()
  request.setEventId(eventId)
  request.setTeamCount(count)
  await client.generateTeamsForEvent(request, {})
}

export const fixSchedule = async (eventId: number, eventDateId: number, producerId: number, brandId: number): Promise<themis_api.FixScheduleResponse.AsObject> => {
  const fixedScheduleRequest = new themis_api.FixScheduleRequest()
  fixedScheduleRequest.setEventId(eventId)
  fixedScheduleRequest.setEventDateId(eventDateId)
  fixedScheduleRequest.setProducerId(producerId)
  fixedScheduleRequest.setBrandId(brandId)
  const response: themis_api.FixScheduleResponse.AsObject = await client.fixSchedule(fixedScheduleRequest, {})
  return response
}

export const splitEventDivision = async(producerId: number, brandId: number, eventId: number, divisionId: number, eventDivisionId: number, rubricId: number, nonCrossover: boolean, newDivision1TeamIds: number[], newDivision2TeamIds: number[], firstNewDivisionName: string, secondNewDivisionName: string, eventDivisionShifts: themis_common.EventDivisionShift.AsObject[] ): Promise<void> => {
  const splitDivisionRequest = new themis_api.SplitEventDivisionRequest();

  splitDivisionRequest.setProducerId(producerId)
  splitDivisionRequest.setBrandId(brandId)
  splitDivisionRequest.setEventId(eventId)
  splitDivisionRequest.setDivisionId(divisionId)
  splitDivisionRequest.setEventDivisionId(eventDivisionId)
  splitDivisionRequest.setRubricId(rubricId)
  splitDivisionRequest.setNonCrossover(nonCrossover)
  // splitDivisionRequest.setEventTeamsDivision1List(newDivision1TeamIds)
  // splitDivisionRequest.setEventTeamsDivision2List(newDivision2TeamIds)
  // splitDivisionRequest.setNameDivision1(firstNewDivisionName)
  // splitDivisionRequest.setNameDivision2(secondNewDivisionName)

  const eventDivisionShiftList: themis_common.EventDivisionShift[] = []

  eventDivisionShifts.forEach((eventShift) => {
    const eventDivisionShift = new themis_common.EventDivisionShift()
    eventDivisionShift.setSortOrder(eventShift.sortOrder)

    const eventFloorDateJudgingPanelShift = new themis_common.EventFloorDateJudgingPanelShift()
    if (eventShift.shift) {
      eventFloorDateJudgingPanelShift.setId(eventShift.shift.id)
      eventFloorDateJudgingPanelShift.setEventFloorDateJudgingPanelId(eventShift.shift.eventFloorDateJudgingPanelId)
    }

    eventDivisionShift.setShift(eventFloorDateJudgingPanelShift)
    eventDivisionShiftList.push(eventDivisionShift)
  })

  // splitDivisionRequest.setEventDivisionShiftsList(eventDivisionShiftList)

  await client.splitEventDivision(splitDivisionRequest, {})
}

export const updateEventTeamName = async(dispatch: AnyDispatchType, teamId: number, updatedTeamName: string, eventId: number, producerId: number, brandId: number) => {
  const event = new themis_api.UpdateEventTeamNameRequest()
  event.setEventTeamId(teamId)
  event.setName(updatedTeamName)
  event.setEventId(eventId)
  event.setProducerId(producerId)
  event.setBrandId(brandId)
  await client.updateEventTeamName(event, {})
}

export const getAthletesAndGuardians = async (eventId: number, brandId: number, producerId: number): Promise<themis_api.AthleteResponse.AsObject> => {
  const getAthletesRequest = new themis_common.Event()
  getAthletesRequest.setId(eventId)
  getAthletesRequest.setBrandId(brandId)
  getAthletesRequest.setProducerId(producerId)
  return (await client.getEventAthletes(getAthletesRequest, {})).toObject()
}

export const editDivName = async (eventId: number | undefined, brandId: number | undefined, producerId: number | undefined, eventDivId: number | undefined, name: string): Promise<void> => {
  if (!eventId || !brandId || !producerId || !eventDivId || !name) return
  const eventDivision = new themis_common.EventDivision()
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  eventDivision.setEvent(event)
  eventDivision.setName(name)
  eventDivision.setId(eventDivId)
  client.updateEventDivision(eventDivision, {})
}

export const getAthletePayReport = async (eventId: number, brandId: number, producerId: number): Promise<themis_common.Event.AsObject> => {
  const event = new themis_api.GetAthletePayReportRequest()
  event.setEventId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  return (await client.getAthletePayReport(event, {})).toObject()
}

export const getProducerJudgeTypeByProducerID = async (producerId: number): Promise<themis_common.ProducerJudgeType.AsObject[]> => {
  const producer = new themis_common.Producer()
  producer.setId(producerId)
  const request = new themis_common.ProducerJudgeType()
  request.setProducer(producer)
  const response = (await client.getProducerJudgeTypeByProducerID(request, {})).toObject()
  return response.producerJudgeTypesList
}

export const addRubricAreaItems = async (producerId: number, label: string, dynamicJson: string,  rubricAreaId: number, order: number): Promise<themis_common.Rubric.AsObject[]> => {
  const request = new themis_common.RubricAreaItem()
  request.setDynamicFormJson(dynamicJson);
  request.setProducerId(producerId)
  request.setRubricAreaId(rubricAreaId)
  request.setLabel(label);
  request.setOrder(order);

  const response: themis_common.Rubrics.AsObject = (await client.addRubricAreaItem(request, {})).toObject()
  return response.itemsList;
}

export const addRubric = async (producerId: number, name: string): Promise<themis_common.Rubric.AsObject[]> => {
  const producer = new themis_common.Producer();
  producer.setId(producerId);

  const request = new themis_common.Rubric()
  request.setName(name);
  request.setProducer(producer);

  const response: themis_common.Rubrics.AsObject = (await client.addRubric(request, {})).toObject()
  return response.itemsList;
}

export const addRubricArea = async (producerId: number, rubricId: number, name: string): Promise<themis_common.Rubric.AsObject[]> => {
  const request = new themis_common.RubricArea()
  request.setName(name);
  request.setRubricId(rubricId);

  const response: themis_common.Rubrics.AsObject = (await client.addRubricArea(request, {})).toObject()
  return response.itemsList;
}

export const updateRubric = async (producerId: number, id: number, name: string, archiveFlag?: ArchiveMap[keyof ArchiveMap]): Promise<themis_common.Rubric.AsObject[]> => {
  const producer = new themis_common.Producer();
  producer.setId(producerId);

  const request = new themis_common.Rubric()
  request.setId(id);
  request.setName(name);
  request.setProducer(producer);

  if(archiveFlag) {
    request.setArchive(archiveFlag);
  }

  const response: themis_common.Rubrics.AsObject = (await client.editRubric(request, {})).toObject()
  return response.itemsList;
}

export const updateRubricArea = async (producerId: number, rubricAreaId: number, name: string): Promise<themis_common.Rubric.AsObject[]> => {
  const request = new themis_common.RubricArea()
  request.setName(name);
  request.setId(rubricAreaId);

  const response: themis_common.Rubrics.AsObject = (await client.editRubricArea(request, {})).toObject()
  return response.itemsList;
}

export const updateRubricAreaItems = async (producerId: number, label: string, dynamicJson: string, rubricAreaId: number, id: number, order: number): Promise<themis_common.Rubric.AsObject[]> => {
  const request = new themis_common.RubricAreaItem()
  request.setDynamicFormJson(dynamicJson);
  request.setProducerId(producerId)
  request.setId(id)
  request.setRubricAreaId(rubricAreaId)
  request.setLabel(label);
  request.setOrder(order);

  const response: themis_common.Rubrics.AsObject = (await client.editRubricAreaItem(request, {})).toObject()
  return response.itemsList;
}

export const deleteRubricAreaItems = async(rubric: any) => {
  const producer = new themis_common.Producer();
  producer.setId(rubric.producer.id);

  const request = new themis_common.Rubric();
  request.setId(rubric.id);
  request.setProducer(producer);
  
  const response: themis_common.Rubrics.AsObject = (await client.deleteRubric(request, {})).toObject()
  return response.itemsList;
}

export const getProducerDivisionRubrics = async (producerId: number, rubricId: number): Promise<themis_api.ProducerDivisionRubricsList.AsObject> => {
  const request = new proto.themis.ProducerDivisionRubricByRubricProducer()
  request.setProducerId(producerId)
  request.setRubricId(rubricId)
  return (await client.getProducerDivisionRubricByRubricProducer(request, {})).toObject()
}

export const addProducerDivisionRubrics = async (list: any[]): Promise<themis_api.ProducerDivisionRubricsList.AsObject> => {
  let rubricObjects: any = [];
  for (const item of list) {
    const producer = new themis_common.Producer();
    producer.setId(item.producerId);

    const season = new themis_common.Season();
    season.setId(item.seasonId);

    const rubric = new themis_common.Rubric();
    rubric.setId(item.rubricId);

    const division = new themis_common.Division();
    division.setId(item.divisionId);

    const request = new themis_common.ProducerDivisionRubric()
    request.setProducer(producer);
    request.setSeason(season);
    request.setRubric(rubric);
    request.setDivision(division);

    rubricObjects.push(request);
  }

  const requestNew = new proto.themis.ProducerDivisionRubricsList()
  requestNew.setProducerDivisionRubricList(rubricObjects)
  return (await client.addProducerDivisionRubric(requestNew, {})).toObject()
}

export const deleteProducerDivisionRubrics = async (list: any): Promise<themis_api.ProducerDivisionRubricsList.AsObject> => {
  let rubricObjects: any = [];
  for (const item of list) {
    const producer = new themis_common.Producer();
    producer.setId(item.producerId);

    const request = new themis_common.ProducerDivisionRubric()
    request.setProducer(producer);
    request.setId(item.id)
    rubricObjects.push(request);
  }

  const requestNew = new proto.themis.ProducerDivisionRubricsList()
  requestNew.setProducerDivisionRubricList(rubricObjects)
  return (await client.deleteProducerDivisionRubric(requestNew, {})).toObject()
}
export const getJudgeTypeByProducerID = async (
  brandId: number | undefined,
  producer: themis_common.Producer.AsObject | undefined
): Promise<themis_common.ProducerJudgeType> => {
  const producerId = producer?.id || undefined;
  const brand = new themis_common.Brand();
  brand.setId(brandId as number);
  const eventProducer = new themis_common.Producer();
  eventProducer.setId(producerId as number);
  const eventJudgeType = new themis_common.ProducerJudgeType();
  eventJudgeType.setProducer(eventProducer);
  const response = (
    await client.getProducerJudgeTypeByProducerID(eventJudgeType, {})
  ).toObject();
  return response;
};
// Remove Judge and Judges Types 
export const deleteEventJudgeShiftByJudgeId = async (
  payload: IDeleteEventJudgeShiftByJudgeId
): Promise<themis_common.EventJudgeShift.AsObject[]> => {
  const eventJudgeShiftJudgeTypRequest =
    new themis_api.EventJudgeShiftJudgeTypRequest();
  const { judgeId, brandId, eventId, producerId, eventFloorDateJudgingPanelShiftId } = payload;
  eventJudgeShiftJudgeTypRequest.setJudgeId(judgeId as number);
  eventJudgeShiftJudgeTypRequest.setProducerId(producerId as number);
  eventJudgeShiftJudgeTypRequest.setEventId(eventId as number);
  eventJudgeShiftJudgeTypRequest.setBrandId(brandId as number);
  eventJudgeShiftJudgeTypRequest.setEventFloorDateJudgingPanelShiftId(eventFloorDateJudgingPanelShiftId as number);
  const response = await client.deleteEventJudgeShiftByJudgeId(
    eventJudgeShiftJudgeTypRequest,
    {}
  );
  const res = response.toObject()
  return res.eventJudgeShiftsList;
};
// Add Single Judge Types 
export const addUpdateEventJudgeShiftJudgeTypeByJudgeId = async (
  payload: IAddJudgeType
): Promise<themis_api.EventJudgeShiftJudgeType> => {
  const addJudgeType = new themis_api.EventJudgeShiftJudgeType();
  const { judgeTypeId, eventJudgeShiftId, judgeId, producerId } = payload;
  // addJudgeType.setJudgeId(judgeId);
  addJudgeType.setProducerId(producerId as number);
  addJudgeType.setEventJudgeShiftId(eventJudgeShiftId as number);
  addJudgeType.setProducerJudgeTypeId(judgeTypeId as number);
  const res = await client.addUpdateEventJudgeShiftJudgeTypeByJudgeId(
    addJudgeType,
    {}
  );
  return res;
};
// Remove Single Judge Types 
export const removeSingleJudgeTypeByJudgeId = async (
  payload: IDeleteJudgeType
): Promise<themis_api.RemoveSingleJudgeTypepRequest> => {
  const removeJudgeType = new themis_api.RemoveSingleJudgeTypepRequest();
  const { brandId, eventId, judgeId, judgeTypeId, producerId } = payload;
  removeJudgeType.setJudgeId(judgeId as number);
  removeJudgeType.setEventId(eventId as number);
  removeJudgeType.setBrandId(brandId as number);
  removeJudgeType.setProducerId(producerId as number);
  removeJudgeType.setProducerJudgeTypeId(judgeTypeId as number);
  const res = await client.removeSingleJudgeTypeByJudgeId(removeJudgeType, {});
  return res;
};
