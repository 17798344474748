import { ACCESS_TOKEN, REFRESH_TOKEN } from "lib/constants";
import { ThemisApiPromiseClient } from "./themis_api_grpc_web_pb";
import environment from "environment";
import { refreshUser } from "./user/userActions";

// Cache to store recent requests and their responses
const requestCache = new Map<string, { timestamp: number; response: any }>();
const CACHE_DURATION = 300; // 10 seconds in milliseconds

const handleLogout = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem("CURRENT_EVENT");
  localStorage.removeItem("CURRENT_TEAM");
  localStorage.removeItem('producerConnectLink');
  window.location.href = "/";
};

let loadingTimer;

class AuthInterceptor {
  async intercept(request: any, invoker: any) {
    let token = localStorage.getItem(ACCESS_TOKEN);
    const metadata = request.getMetadata();
    metadata.Authorization = 'Bearer ' + token;

    // Lazy import the store to ensure it's initialized
    const { store } = await import('../store/store');

    // Increment the request counter
    store.dispatch({ type: 'INCREMENT_REQUEST_COUNT' });

    // Create a cache key using the method name and request parameters
    const requestParams = request.toObject ? request.toObject() : request;
    const cacheKey = `${request.c.name}-${JSON.stringify(requestParams)}`;

    const cached = requestCache.get(cacheKey);
    if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
      console.log("Returning cached response");

      // Decrement the request counter when returning cached data
      store.dispatch({ type: 'DECREMENT_REQUEST_COUNT' });
      return cached.response;
    }

    try {
      // Make the actual gRPC request
      const response = await invoker(request);

      // Cache the response with a timestamp
      requestCache.set(cacheKey, { timestamp: Date.now(), response });

      // Decrement the request counter after the request completes
      store.dispatch({ type: 'DECREMENT_REQUEST_COUNT' });

      return response;
    } catch (error) {
      // Decrement the request counter in case of an error
      store.dispatch({ type: 'DECREMENT_REQUEST_COUNT' });

      if (error.message.indexOf('error validating the JWT') !== -1) {
        console.error('JWT error, maybe expired, refreshing token.', error.message);

        token = localStorage.getItem(ACCESS_TOKEN) || localStorage.getItem(OLD_ACCESS_TOKEN) || '';
        if (!token.length) {
          handleLogout();
          store.dispatch({ type: 'DECREMENT_REQUEST_COUNT' });

          throw error;
        }

        if (request.c.name !== "/themis.ThemisApi/Refresh") {
          store.dispatch({ type: 'DECREMENT_REQUEST_COUNT' });

          await refreshUser();
        } else {
          store.dispatch({ type: 'DECREMENT_REQUEST_COUNT' });

          handleLogout();
        }

        metadata.Authorization = 'Bearer ' + token;

        // Retry the request after refreshing the token
        const response = await invoker(request);

        requestCache.set(cacheKey, { timestamp: Date.now(), response });

        // Decrement the request counter after successful retry
        store.dispatch({ type: 'DECREMENT_REQUEST_COUNT' });

        return response;
      } else {
        store.dispatch({ type: 'DECREMENT_REQUEST_COUNT' });

        throw error;
      }
    }
  }
}

const authInterceptor = new AuthInterceptor();

const clientOptions = {
  unaryInterceptors: [authInterceptor],
  streamInterceptors: []
};

export const client = new ThemisApiPromiseClient(environment.BASE_URL, null, clientOptions);