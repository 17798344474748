const initialState = {
  activeRequests: 0, // Track the number of active requests
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INCREMENT_REQUEST_COUNT':
      return {
        ...state,
        activeRequests: state.activeRequests + 1,
      };
    case 'DECREMENT_REQUEST_COUNT':
      return {
        ...state,
        activeRequests: Math.max(state.activeRequests - 1, 0), // Prevent decrementing below 0
      };
    default:
      return state;
  }
};

export default loadingReducer;