import React from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import { Slide, Theme } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
export const Transition = React.forwardRef(function Transition(props: TransitionProps & {
  children?: React.ReactElement;
}, ref: React.Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const autoCompleteStyles = makeStyles(theme => ({
  option: {
    '&[aria-disabled="true"]': {
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }
}));
export const useStyles = makeStyles(theme => ({
  dndItem: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  table: {
    minWidth: 650
  },
  form: {
    width: '100%',
    // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
    backgroundColor: '#b30808',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: "#d32828"
    }
  },
  divisionSubmit: {
    backgroundColor: '#b30808',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: "#d32828"
    }
  },
  logoCheck: {
    maxHeight: 100,
    maxWidth: 200,
    alignSelf: "center"
  },
  register: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#0824b3',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: "#1e36b1"
    }
  },
  autoCompleteHeader: {
    marginTop: theme.spacing(2)
  },
  warning: {
    color: 'red'
  },
  headerRoot: {
    flexGrow: 1,
    paddingBottom: theme.spacing(5),
    backgroundColor: theme.palette.background.default,
    top: '30px'
  },
  headerLogo: {
    height: '100px',
    maxWidth: '100%',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  menu: {
    flexGrow: 1,
    textAlign: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.text.secondary
  },
  button: {
    marginTop: theme.spacing(3),
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  home: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.h1.fontWeight,
    lineHeight: theme.typography.h1.lineHeight
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  menuItem: {
    margin: -5
  },
  dropDownLogo: {
    maxHeight: 40
  },
  headerTypo: {
    textAlign: 'left',
    width: 'auto',
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(3)
  },
  openRegTitle: {
    marginRight: theme.spacing(2)
  },
  openRegOption: {
    display: "block",
    paddingTop: theme.spacing(.5),
    cursor: "pointer"
  },
  detailType: {
    textAlign: 'right'
  },
  detailValue: {
    textAlign: 'left',
    paddingLeft: theme.spacing(1)
  },
  listItem: {
    width: '70%',
    fontSize: 20,
    paddingTop: theme.spacing(1),
    display: 'block',
    float: 'left',
    textDecoration: 'none',
    color: theme.palette.secondary.main
  },
  listItem100: {
    width: '100%',
    fontSize: 20,
    paddingTop: theme.spacing(1),
    display: 'block',
    float: 'left',
    textDecoration: 'none',
    color: theme.palette.secondary.main
  },
  listItem100Clickable: {
    width: '100%',
    fontSize: 16,
    paddingTop: theme.spacing(1),
    display: 'block',
    float: 'left',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    cursor: "pointer"
  },
  splitDivisionTeamItem: {
    width: '100%',
    fontSize: 15,
    display: 'block',
    float: 'left',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    cursor: "pointer"
  },
  splitDivisionDivItem: {
    width: '100%',
    fontSize: 15,
    display: 'block',
    float: 'left',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    cursor: "pointer"
  },
  splitDivisionUserGridRight: {
    paddingLeft: 15,
    paddingTop: 10
  },
  splitDivisionTeamDropArea: {
    border: "1px solid gray",
    height: "90%",
    borderRadius: theme.spacing(4),
    textAlign: "center"
  },
  listDeleteEdit: {
    float: 'right'
  },
  programSelect: {
    marginTop: theme.spacing(3)
  },
  fullWidth: {
    width: "100%"
  },
  smallFont: {
    fontSize: 10
  },
  imageHolder: {
    backgroundColor: "rgba(0,0,0,0)"
  },
  brandImageHolder: {
    backgroundColor: "rgba(0,0,0,0)",
    marginLeft: theme.spacing(2)
  },
  brandHolder: {
    marginLeft: theme.spacing(2)
  },
  dateForm: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  fullWidthField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  dateField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 155
  },
  numField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 55
  },
  tightList: {
    paddingTop: 0,
    paddingBottom: 0
  },
  tightListItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  tightListItemLink: {
    fontSize: 20,
    paddingTop: theme.spacing(0),
    display: 'block',
    float: 'left',
    textDecoration: 'none',
    color: theme.palette.secondary.main
  },
  grouper: {
    border: '1px solid black',
    borderColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    fontSize: '12px',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    width: '100%'
  },
  breadcrumb: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    }
  },
  lastBreadcrumb: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    }
  },
  menuImage: {
    maxHeight: "30px"
  },
  navigationMenu: {
    minWidth: "600px" // TODO: Fix this for mobile
  },
  gridForm: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    minHeight: 118
  },
  cleanLink: {
    textDecoration: "none",
    color: theme.palette.primary.main
  },
  cardSpacing: {
    margin: theme.spacing(2),
    width: "100%"
  },
  userGrid: {
    padding: theme.spacing(2)
  },
  userGridLeft: {
    borderBottom: "1px solid black",
    borderBottomColor: theme.palette.secondary.main,
    padding: theme.spacing(1)
  },
  userGridRight: {
    borderLeft: "1px solid black",
    borderLeftColor: theme.palette.secondary.main,
    padding: theme.spacing(1)
  },
  userPadding: {
    padding: theme.spacing(1)
  },
  userHeader: {
    borderBottom: "1px solid black",
    borderBottomColor: theme.palette.secondary.main
  },
  permissionGroupTitle: {
    paddingTop: theme.spacing(1)
  },
  permissionGroupBox: {
    padding: 2,
    margin: 2,
    border: "1px solid grey",
    width: "100%",
    display: "grid"
  },
  eventPricingTop: {
    borderBottom: "1px solid black",
    borderBottomColor: theme.palette.secondary.main
  },
  eventPricingHeaders: {
    paddingTop: theme.spacing(2)
  },
  saveIcon: {
    marginRight: theme.spacing(1)
  },
  verbDropdown: {
    border: "1px solid black",
    borderColor: theme.palette.secondary.main
  },
  primaryBackgroundColor: {
    backgroundColor: theme.palette.background.default
  },
  bugReport: {
    position: "fixed",
    bottom: "15px",
    right: "15px"
  },
  eula: {
    maxHeight: 200,
    overflow: 'auto',
    padding: 15,
    marginTop: 20,
    marginBottom: 20
  },
  paymentGridHeader: {
    borderBottom: "1px solid white",
    borderColor: theme.palette.secondary.main,
    paddingBottom: theme.spacing(1)
  },
  paymentGridHeaderLeft: {
    marginLeft: 0
  },
  root: {
    flexGrow: 1
  },
  paperGridItem: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    border: 'none',
    boxShadow: 'none'
  },
  personGridRow: {
    borderBottom: "1px solid #545252",
    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
      paddingBottom: 0,
      lineHeight: 0.8
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 10,
      paddingBottom: 10,
      lineHeight: 1.2
    }
  },
  personGridItem: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
      paddingBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 10,
      paddingBottom: 10
    }
  },
  personGridHeader: {
    [theme.breakpoints.down('lg')]: {
      lineHeight: 0,
      paddingBottom: 5
    },
    [theme.breakpoints.up('md')]: {
      lineHeight: 1.2,
      paddingBottom: 0
    }
  },
  personGridButton: {
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '22px'
    }
  },
  formColorOverPrimary: {
    '& input:valid:focus + fieldset, & input:valid:hover + fieldset, & input:valid + fieldset, & input, & label, & label.Mui-focused, & value, & .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, .MuiSvgIcon-root': {
      borderColor: theme.palette.common.white,
      color: theme.palette.common.white
    }
  },
  customMarkdownStyles: {
    "& h1, h2, h3, h4, h5, h6": {
      marginTop: 5,
      marginBottom: 10
    },
    "& p": {
      marginTop: 5,
      marginBottom: 5
    },
    "& img[alt='event-grid-icon']": {
      width: "30px"
    }
  },
  formControl: {
    marginTop: 16,
    marginBottom: 8
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  scheduleSaveButton: {
    backgroundColor: '#b30808',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: "#d32828"
    },
    marginTop: "12px",
    marginLeft: "5px"
  },
  grouperForm: {
    border: '1px solid black',
    borderColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    fontSize: '12px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  rubricScoring: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  horizontalLine: {
    border: "1px solid black",
    borderColor: theme.palette.secondary.main,
    width: '90%'
  },
  infoIcon: {
    width: '30px',
    cursor: 'pointer'
  },
  textBoxStyle: {
    marginTop: '15px'
  },
  helperText: {
    whiteSpace: 'pre-line',
    fontSize: '12px'
  },
  previewEventScheduleReportHeaderLogo: {
    height: '70px',
    maxWidth: '40%',
    paddingTop: "3px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  eventScheduleReport: {
    padding: "20px"
  },
  eventScheduleReportHeaderSection: {
    background: "black",
    color: "white",
    height: "90px",
    alignItems: "center",
    width: "auto",
    marginBottom: "25px"
  },
  eventScheduleReportPanelDivisions: {
    flexGrow: 1,
    marginTop: "15px",
    marginBottom: "10px",
    width: "101%"
  },
  templateMatrix: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: theme.spacing(1)
  },
  templateMatrixTh: {
    borderBottom: '1px solid',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: theme.typography.h6.lineHeight
  },
  templateMatrixTd: {
    borderBottom: '1px solid',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    textAlign: 'center',
    fontSize: 16,
    lineHeight: theme.typography.h6.lineHeight
  },
  templateMatrixRadio: {
    cursor: 'pointer',
    transform: 'scale(1.5)'
  }
}),
// makeStyles options
{
  index: 1
});
export const editorTheme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000"
    }
  }
});
Object.assign(editorTheme, {
  overrides: {
    MUIRichTextEditor: {
      editor: {
        backgroundColor: "#ebebeb",
        padding: "20px",
        height: "100px",
        maxHeight: "200px",
        overflow: "auto"
      },
      toolbar: {
        display: "none"
      }
    }
  }
});