import { IAppState } from "store/store"
import { JudgeEventPermissions, ProducerPermissions, ProgramPermissions } from "./userReducer"
import { BothSides, Categories, PermissionLists } from "./userActions"
import themis_common from "store/themis_common_pb"

export const selectCurrentUser = (state: IAppState): string | null =>
  (state.userState?.user?.name) ? state.userState?.user?.name : null

export const selectCurrentUserEmail = (state: IAppState): string | null =>
  (state.userState?.user?.email) ? state.userState?.user?.email : null

export const selectSuperUser = (state: IAppState): boolean =>
  (state.userState?.user?.superUser) ? state.userState?.user?.superUser : false

export const selectUserProgramPermissions = (state: IAppState): ProgramPermissions =>
  (state.userState?.permissions?.programs) ? state.userState.permissions.programs : {}

export const selectUserProducerPermissions = (state: IAppState): ProducerPermissions =>
  (state.userState?.permissions?.producers) ? state.userState.permissions.producers : {}

export const selectCurrentLoginState = (state: IAppState): boolean =>
  state.userState?.loggedIn

export const selectTryLogin = (state: IAppState): boolean =>
  state.userState?.tryLogin

export const selectUserPrograms = (state: IAppState): ProgramPermissions =>
  state.userState?.permissions?.programs ? state.userState.permissions.programs : {}

export const selectCurrentRegisterProgram = (state: IAppState): boolean | undefined =>
  state.userState?.registerProgram

export const selectCurrentCategories = (state: IAppState): Categories | undefined =>
  state.userState?.categories

export const selectCurrentPermissionLists = (state: IAppState): PermissionLists | undefined => state.userState?.permissionLists

export const selectUserProducers = (state: IAppState): ProducerPermissions =>
  state.userState?.permissions?.producers ? state.userState.permissions.producers : {}

export const selectJudgeEvents = (state: IAppState): any => state.userState?.permissions?.judges

export const selectBothSides = (state: IAppState): BothSides => {
  return { producer: state.userState?.producer, program: state.userState?.program, judge: state.userState?.judge }
}

export const selectPermissionCodes = (state: IAppState): themis_common.PermissionCodes.AsObject => {
  return state.userState.permissionCodes
}

export const selectCurrentState = (state: IAppState): IAppState => {
  return state
}

export const selectCurrentUserId = (state: IAppState): number | null =>
  (state.userState?.user?.id) ? state.userState?.user?.id : null

export const selectUserImpersonated = (state: IAppState): string | null =>
  (state.userState?.user?.impersonated) ? state.userState?.user?.impersonated : null

export const selectUserJudgeEvents = (state: IAppState): JudgeEventPermissions =>
  state.userState?.permissions?.judges
